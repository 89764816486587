import { Box, Col, Img, Row } from '@qonsoll/react-design'
import { Delivery, Order, Payment, Waiting, Wallet } from './components'
import { ExchangeStyled, StepsStyled } from './Exchange.styles'
import { Fragment, useMemo, useState } from 'react'
import { createDocument } from 'services/helpers'

import { CURRENCY } from '__constants__'
import logo from 'assets/optСhange3.png'
import { useCurrentScreen } from 'helpers'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const Exchange = () => {
  const history = useHistory()

  const { orderId } = useParams()
  const orderData = JSON.parse(localStorage.getItem(orderId))

  const [step, setStep] = useState(orderData?.step ?? 0)
  const { xs, md } = useCurrentScreen()

  const receivingType = useMemo(
    () => orderData?.receiving?.type,
    [orderData?.receiving?.type]
  )

  const dispatchType = useMemo(
    () => orderData?.dispatch?.type,
    [orderData?.dispatch?.type]
  )

  const isCryptoDispatch = useMemo(
    () =>
      !!CURRENCY.find(({ key, isCrypto }) => key === dispatchType && isCrypto),
    [receivingType]
  )

  const isCryptoReceiving = useMemo(
    () =>
      !!CURRENCY.find(({ key, isCrypto }) => key === receivingType && isCrypto),
    [receivingType]
  )

  const stepsItems = useMemo(
    () => [
      {
        title: 'Choose wallet'
      },
      isCryptoDispatch && {
        title: 'Information'
      },
      {
        title: 'Payment'
      },
      { title: 'Waiting' }
    ],
    []
  )

  const handleNextStep = () => {
    setStep((prev) => {
      const orderData = JSON.parse(localStorage.getItem(orderId))
      if (prev == 2) createDocument('submit', { status: 'done', orderData })
      const step = stepComponent?.length - 1 !== prev ? prev + 1 : prev
      orderData.step = step

      localStorage.setItem(orderId, JSON.stringify(orderData))
      return step
    })
  }
  const handlePrevStep = () =>
    setStep((prev) => {
      const orderData = JSON.parse(localStorage.getItem(orderId))
      const step = prev > 0 ? prev - 1 : prev
      orderData.step = step
      localStorage.setItem(orderId, JSON.stringify(orderData))
      return step
    })

  const stepProps = {
    handleNextStep,
    handlePrevStep,
    isCryptoDispatch,
    isCryptoReceiving
  }
  const stepComponent = useMemo(
    () =>
      [
        // <Delivery {...stepProps} />,
        <Wallet {...stepProps} />,
        <Order {...stepProps} />,
        isCryptoDispatch && <Payment {...stepProps} />,
        <Waiting {...stepProps} />
      ]?.filter(Boolean),
    [step, setStep, isCryptoDispatch]
  )

  const onLogoClick = () => history.push('/dashboard')

  const colProps = {
    steps: 12,
    content: xs ? 12 : !md ? 12 : 6
  }

  const logoWrapperProps = {
    // top: 0,
    // left: 0,
    // transform: 'translate(20%, 20%)',
    width: '86px',
    padding: '12px',
    // position: 'absolute',
    backgroundColor: 'rgba(0,0,0, 0.2)',
    borderRadius: '12px'
    // minWidth: '100px',
    // cursor: 'pointer'
  }
  return (
    <Fragment>
      <div style={{ backgroundColor: 'rgb(65, 70, 202)', padding: '16px' }}>
        <div style={logoWrapperProps}>
          <Img
            style={{ cursor: 'pointer' }}
            src={logo}
            width="100%"
            onClick={onLogoClick}
          />
        </div>
      </div>
      <ExchangeStyled>
        <Row v="center" h="center" noGutters flexDirection="column">
          <Col cw={colProps.steps} mb={4} className="align-center">
            <StepsStyled
              current={step}
              items={stepsItems}
              direction={!md ? 'vertical' : 'horizontal'}
            />
          </Col>
          <Col cw={colProps.content}>
            <Box background="#fff" py={64} px={24} borderRadius="12px">
              <Row>
                <Col cw={12}>{stepComponent?.filter((_, i) => i === step)}</Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </ExchangeStyled>
    </Fragment>
  )
}

export default Exchange
