import { InputStyled } from './CardNumberInput.styled'
import PropTypes from 'prop-types'

const CardNumberInput = (props) => {
  const { value, onChange, isNumber, ...rest } = props

  const handleChange = (e) => {
    const stringWithoutSpace = e.target.value?.replaceAll(' ', '')
    const numberReg = /^\d*(\.\d*)?$/

    const isNumberValue = !numberReg.test(stringWithoutSpace)

    if (isNumberValue) return

    const transformedString = stringWithoutSpace
      .replace(/(\d{4})/g, '$1 ')
      ?.trim()

    onChange?.(transformedString)
  }

  return (
    <InputStyled
      value={value}
      onChange={isNumber ? handleChange : onChange}
      {...rest}
    />
  )
}

CardNumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isNumber: PropTypes.bool
}

export default CardNumberInput
