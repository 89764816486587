import { Box, Col, Divider, Img, Row, Text, Title } from '@qonsoll/react-design'
import { Button, Typography } from 'antd'

import { ButtonSecondary } from 'components'
import PropTypes from 'prop-types'
import { getWallet } from 'helpers'
import { useCurrentScreen } from 'helpers'
import { useParams } from 'react-router-dom'

const Payment = (props) => {
  const { handleNextStep, handlePrevStep } = props

  const { orderId } = useParams()

  const { xs } = useCurrentScreen()

  const orderData = localStorage.getItem(orderId)

  const { dispatch = {}, stable = '' } = JSON.parse(orderData)

  const { qrCode, walletNumber } = getWallet(dispatch.type, stable)

  return (
    <Row h="center" noGutters>
      <Col cw={12}>
        <Title level={3} textAlign="center">
          Send {dispatch?.sum} {dispatch?.type} to the wallet specified below
        </Title>
      </Col>
      <Col cw={12} mb={4}>
        <Title type="danger" textAlign="center" level={3}>
          (must be in one transaction)
          <span style={{ color: '#000' }}>.</span>
        </Title>
      </Col>
      <Col cw={12} mb={4}>
        <Box
          background="var(--ql-color-secondary)"
          borderRadius="var(--card-radius)"
          p={3}>
          <Row noGutters mb={3}>
            <Col cw={12}>
              <Text variant="body2" mb={3}>
                Wallet number:
              </Text>

              <Box display="flex" justifyContent="center" alignItems="center">
                <Img width={xs ? '30%' : '25%'} src={qrCode} />
              </Box>
              <Divider my={3} />
              <Typography.Paragraph
                style={{
                  width: '100%',
                  margin: 0,
                  textAlign: 'center',
                  wordBreak: 'break-word'
                }}
                copyable={true}>
                {walletNumber}
              </Typography.Paragraph>
            </Col>
          </Row>
        </Box>
      </Col>
      <Col cw={12}>
        <Row>
          <Col>
            <ButtonSecondary onClick={handlePrevStep}>Back</ButtonSecondary>
          </Col>
          <Col>
            <Button onClick={handleNextStep}>Sent</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

Payment.propTypes = {
  handleNextStep: PropTypes.func,
  handlePrevStep: PropTypes.func
}

export default Payment
