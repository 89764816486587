import { Menu, MenuItem } from '@qonsoll/react-design'

import styled from 'styled-components'

const MenuStyled = styled(Menu)`
  cursor: pointer;
  padding: 8px 0px;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  max-width: 632px;
  & .ant-dropdown-menu-item {
    width: 300px !important;
  }
`
const MenuItemStyled = styled(MenuItem)`
  margin: 0 8px;
  border-radius: 6px !important;
  padding: 10px 6px;
`

const ItemStyled = styled.div`
  border-radius: 4px;
  border: 1px solid var(--ql-color-secondary-lighten1);
  /* border-color: var(--ql-color-secondary); */

  padding: 6px 20px;
  margin: 8px;
  transition: all 0.5s;

  &:hover {
    background-color: var(--ql-color-secondary-lighten1);
  }
`

export { MenuStyled, MenuItemStyled, ItemStyled }
