import { Col, Container, Link, Row, Text } from '@qonsoll/react-design'

import { useCurrentScreen } from 'helpers'

const Footer = () => {
  const { xs, lg } = useCurrentScreen()

  const colWidth = xs ? 12 : !lg ? 6 : null
  return (
    <Container>
      <Row mb={2} className="row-gap-24">
        <Col h="center" cw={colWidth} order={!lg ? 1 : 0}>
          <Link>
            <Text color="#3ec19a" fontSize="14px" fontWeight={700}>
              www.optChange.com
            </Text>
          </Link>
          <Text color="#7b818c" fontWeight={600} fontSize="14px">
            2018-2024. Are right are reserved.
          </Text>
        </Col>
        {/* <Col h="center" cw={colWidth} order={0}>
          <Text fontSize="13px" color="#7b818c">
            Политика конфиденциальности{' '}
          </Text>
        </Col> */}
        {/* <Col h="center" cw={colWidth} order={0}>
          <Text fontSize="13px" color="#7b818c">
            Правила использования сайта{' '}
          </Text>
        </Col> */}
        <Col h="center" cw={colWidth} order={!lg ? 1 : 0}>
          <Link>
            <Text color="#3ec19a" fontSize="14px" fontWeight={700}>
              support@optChange.com
            </Text>
          </Link>
          <Link>
            <Text
              onClick={() => window.open('https://teleg.run/OptChangeSupport')}
              color="#3ec19a"
              fontSize="14px"
              fontWeight={700}>
              m.me/optChange.com
            </Text>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
