import { Box, Card, Col, Row, Text, Title } from '@qonsoll/react-design'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from 'antd'
import { ButtonSecondary } from 'components'
import { Icon } from '@qonsoll/icons'
import { LoadingOutlined } from '@ant-design/icons'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'

const Waiting = (props) => {
  const { isCryptoReceiving, isCryptoDispatch, handlePrevStep } = props
  const { orderId } = useParams()
  const history = useHistory()

  const orderData = localStorage.getItem(orderId)

  const { dispatch = {}, receiving = {}, wallet } = JSON.parse(orderData)

  const circleIcon = (
    <Icon
      name="CircleOutlined"
      size={16}
      mr={2}
      fill="var(--ql-color-secondary-lighten1)"
    />
  )

  const loadingIcon = (
    <LoadingOutlined style={{ color: 'var(--ql-color-accent)' }} />
  )

  const handleClick = () => history.push(PATHS.DASHBOARD)

  return (
    <Row h="center" noGutters>
      <Col cw={12}>
        <Title level={3} textAlign="center">
          {`${isCryptoDispatch ? 4 : 3}. Awaiting transaction`}
        </Title>
      </Col>
      <Col cw={12} mb={4}>
        <Text textAlign="center">
          Your order is being processed. You will receive status updates via
          phone or email. <b>(if the corresponding methods are selected)</b>.
        </Text>
      </Col>

      <Col cw={12} mb={3}>
        <Box
          background="var(--ql-color-secondary)"
          borderRadius="var(--card-radius)"
          p={3}>
          <Row noGutters>
            <Col cw="auto" mb={2}>
              <Row>
                <Col cw={12} mb={2}>
                  <Text variant="caption1" type="secondary">
                    Order ID:
                  </Text>
                </Col>
                <Col cw={12}>
                  <Text variant="body1">{orderId || 'n/a'}</Text>
                </Col>
              </Row>
            </Col>
            <Col cw={12}>
              <Row>
                <Col cw={12} mb={2}>
                  <Text variant="caption1" type="secondary">
                    {`Receiving to ${isCryptoReceiving ? 'wallet' : 'card'}`}
                  </Text>
                </Col>
                <Col cw={12}>
                  <Text variant="body1" style={{ wordBreak: 'break-all' }}>
                    {wallet || 'n/a'}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Col>

      <Col cw={12} mb={3}>
        <Card>
          <Row noGutters mb={3}>
            <Col>
              <Title level={5}>Sending: </Title>
            </Col>
            <Col cw="auto">
              <Title level={4}>{`${dispatch?.type} ${dispatch?.sum}`}</Title>
            </Col>
          </Row>
          {!isCryptoDispatch && (
            <Row noGutters>
              <Col cw={12} mb={2}>
                <Box display="flex">
                  <Box mr={2}>{loadingIcon}</Box>
                  <Text type="secondary">Bank payment confirmation</Text>
                </Box>
              </Col>
            </Row>
          )}
          {isCryptoDispatch && (
            <Row noGutters>
              <Col cw={12} mb={2}>
                <Box display="flex">
                  <Box mr={2}>{loadingIcon}</Box>
                  <Text type="secondary">
                    Searching for your transaction in the blockchain
                  </Text>
                </Box>
              </Col>
              <Col cw={12}>
                <Box display="flex" alignItems="center">
                  {circleIcon}
                  <Text type="secondary">
                    Awaiting transaction confirmation
                  </Text>
                </Box>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
      <Col cw={12} mb={3}>
        <Card>
          <Row noGutters mb={3}>
            <Col>
              <Title level={5}>Receiving:</Title>
            </Col>
            <Col cw="auto">
              <Title level={4}>{`${receiving?.type} ${receiving?.sum}`}</Title>
            </Col>
          </Row>
          {!isCryptoReceiving && (
            <Row noGutters>
              <Col cw={12}>
                <Box display="flex" alignItems="center">
                  {circleIcon}
                  <Text type="secondary">Sending money to your card</Text>
                </Box>
              </Col>
            </Row>
          )}

          {isCryptoReceiving && (
            <Row noGutters>
              <Col cw={12}>
                <Box display="flex" alignItems="center">
                  {circleIcon}
                  <Text type="secondary">
                    Creating a transaction in the blockchain
                  </Text>
                </Box>
              </Col>
              <Col cw={12}>
                <Box display="flex" alignItems="center">
                  {circleIcon}
                  <Text type="secondary">
                    Awaiting transaction confirmation
                  </Text>
                </Box>
              </Col>
            </Row>
          )}
        </Card>
      </Col>
      <Col cw={12}>
        <Row>
          <Col>
            <ButtonSecondary onClick={handlePrevStep}>Back</ButtonSecondary>
          </Col>
          <Col>
            <Button onClick={handleClick}>To main</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

Waiting.propTypes = {
  isCryptoReceiving: PropTypes.bool,
  isCryptoDispatch: PropTypes.bool,
  handlePrevStep: PropTypes.func
}

export default Waiting
