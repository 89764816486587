import { useEffect, useState } from 'react'

import { PERCENT_LIST } from '__constants__'

const SHORT_KEYS = {
  BTC: 'bitcoin',
  ETH: 'ethereum',
  USDT: 'tether',
  LTC: 'litecoin',
  BNB: 'binance-coin',
  XRP: 'xrp',
  NOT: 'not',
  TON: 'ton'
}

// BINANCE_PRICE
const getBinancePrice = async (symbol, secondSymbol) => {
  try {
    if (!symbol || !secondSymbol) {
      return null
    }
    const priceDataJSON = await fetch(
      `https://api.binance.com/api/v3/ticker/price?symbol=${symbol}${secondSymbol}`
    )

    const priceChangeJSON = await fetch(
      `https://api.binance.com/api/v3/ticker/24hr?symbol=${symbol}${secondSymbol}`
    )

    const priceData = await priceDataJSON.json()
    const priceChange = await priceChangeJSON.json()

    return {
      price: priceData.price,
      priceChange: priceChange.priceChangePercent,
      count: priceChange.volume,
      volume: priceChange.quoteVolume
    }
  } catch (error) {
    console.error('Error during get BB Data')
    return null
  }
}

// COINCAP_DATA
const getCoinCapPrice = async (symbol) => {
  try {
    const coinCapDataJSON = await fetch(
      `https://api.coincap.io/v2/assets/${SHORT_KEYS[symbol]}`
    )

    const coinCapData = await coinCapDataJSON.json()

    return {
      price: coinCapData.data.priceUsd,
      priceChange: coinCapData.data.changePercent24Hr,
      count: coinCapData.data.supply,
      volume: coinCapData.data.volumeUsd24Hr
    }
  } catch (error) {
    console.error('Error during get CC Data', error.message)
    return null
  }
}

const transformPrice = (symbol, secondSymbol, price, sum, isReverse) => {
  if (!price) return 0

  let percent = PERCENT_LIST[`${symbol}${secondSymbol}`] ?? 0

  const computedPercent = (+price / 100) * percent
  const computedPrice = +price + computedPercent

  const computedSum = isReverse ? +sum / computedPrice : +sum * computedPrice

  const endingSum = Number(computedSum)
  return endingSum <= 0 ? 0 : Number(endingSum.toFixed(8))
}

const useGetCryptoPrice = (symbol, secondSymbol = 'USDT') => {
  const [price, setPrice] = useState(null)

  const getCryptoPrice = async () => {
    const binanceData = await getBinancePrice(symbol, secondSymbol)

    const coinCapData =
      !binanceData && (await getCoinCapPrice(symbol, secondSymbol))

    const data = binanceData ?? coinCapData

    if (data) {
      let formattedCount = String(Number(data.count).toFixed(0))

      formattedCount =
        formattedCount?.length > 11
          ? formattedCount?.slice(0, 5)
          : formattedCount?.slice(0, 4)

      let volume = Number(data.volume / 100000).toFixed(0)

      if (volume && symbol === 'USDT' && secondSymbol === null) {
        volume = (volume / 2.5).toFixed(0)
      }

      setPrice({
        count: formattedCount,
        volume,
        priceChange: Number(data.priceChange).toFixed(2),
        price: transformPrice(symbol, secondSymbol, data.price, 1)
      })
    }
  }

  useEffect(() => {
    let isMounted = true
    isMounted && getCryptoPrice()

    return () => (isMounted = false)
  }, [])

  return price
}

export default useGetCryptoPrice

// const getCryptoPrice = async () => {
//   try {
//     const coinCapDataJSON = await fetch(
//       `https://api.coincap.io/v2/assets/${SHORT_KEYS[symbol]}`
//     )

//     if (symbol === 'USDT' && secondSymbol === null) {
//       const coinCapData = await coinCapDataJSON.json()

//       setPrice(() => ({
//         price: transformPrice(coinCapData?.data.priceUsd, 1),
//         priceChange: Number(coinCapData.data.changePercent24Hr).toFixed(2),
//         count: Number(coinCapData.data.supply).toFixed(0).slice(0, 5),
//         volume: Number(coinCapData.data.volumeUsd24Hr / 100000).toFixed(0)
//       }))

//       return
//     }

//     coinCapData &&
//       priceData &&
//       priceChange &&
//       setPrice(() => {
//         const count = String(Number(coinCapData.data.supply).toFixed(0))

//         const computedCount =
//           count?.length > 11 ? count?.slice(0, 5) : count?.slice(0, 4)

//         let computedVolume = (priceChange?.volume / 6) * priceData?.price
//         if (computedVolume > 10000000) {
//           computedVolume = computedVolume / 5
//         }

//         return {
//           price: transformPrice(priceData?.price, 1),
//           priceChange: Number(coinCapData.data.changePercent24Hr).toFixed(2),
//           count: computedCount,
//           volume: Number(coinCapData.data.volumeUsd24Hr / 100000).toFixed(0)
//         }
//       })

//     return price
//   } catch (error) {
//     console.error(error)
//   }
// }
