import React from 'react'

const RubleIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 48.000000 48.000000"
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
        fill="#32C99C"
        stroke="none">
        <path
          d="M130 330 c0 -89 0 -90 -25 -90 -18 0 -25 -5 -25 -20 0 -15 7 -20 25
    -20 16 0 25 -6 25 -15 0 -9 -9 -15 -25 -15 -18 0 -25 -5 -25 -20 0 -15 7 -20
    25 -20 22 0 25 -4 25 -35 0 -31 3 -35 25 -35 22 0 25 4 25 35 0 34 1 35 40 35
    33 0 40 3 40 20 0 17 -7 20 -40 20 -29 0 -40 4 -40 15 0 12 14 15 65 15 77 0
    118 18 140 60 19 37 19 63 0 100 -24 46 -62 60 -165 60 l-90 0 0 -90z m200 30
    c11 -11 20 -33 20 -50 0 -50 -30 -70 -105 -70 l-65 0 0 70 0 70 65 0 c52 0 69
    -4 85 -20z"
        />
      </g>
    </svg>
  )
}
export default RubleIcon
