import {
  useGetPopularCurrency,
  useGetPopularCurrencyTableConfig
} from 'domains/PopularCurrency/hooks'

import { Table } from 'antd'

const PopularCurrencyTable = () => {
  const popularCurrency = useGetPopularCurrency()

  const { columns, tableProps } = useGetPopularCurrencyTableConfig()

  return (
    <Table
      dataSource={popularCurrency}
      columns={columns}
      {...tableProps}
      pagination={{ position: ['none', 'none'] }}
    />
  )
}

export default PopularCurrencyTable
