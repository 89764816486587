import {
  Box,
  Col,
  Container,
  Img,
  Row,
  Text,
  Title
} from '@qonsoll/react-design'

import advertisement from '../../../../assets/Advertisement2.png'
import { useCurrentScreen } from 'helpers'

const advantagesConfig = [
  {
    title: 'Convenience',
    description:
      'OptChange offers a simple functionality, well-designed UI/UX, and a clear operational algorithm. Complete cryptocurrency exchanges in 4 easy steps.',
    imageSrc:
      'https://changeit.com.ua/_ipx/f_webp,s_40x40/https://a.storyblok.com/f/120064/x/07931185cf/icon-1.svg'
  },
  {
    title: 'Low Fees',
    description:
      'OptChange provides some of the most competitive conditions. Perform cryptocurrency transactions with minimal fees.',
    imageSrc:
      'https://changeit.com.ua/_ipx/f_webp,s_40x40/https://a.storyblok.com/f/120064/x/beadf8e121/icon-2.svg'
  },
  {
    title: 'Transparency',
    description:
      'OptChange stands for reliability and no hidden fees. You receive exactly what is shown on the screen. Exchange cryptocurrency with no risks and no overpayments.',
    imageSrc:
      'https://changeit.com.ua/_ipx/f_webp,s_40x40/https://a.storyblok.com/f/120064/x/b9cf680c7d/icon-3.svg'
  }
]

const OurAdvantages = () => {
  const { isSmallScreen, xl } = useCurrentScreen()

  return (
    <Container>
      <Row mb={5}>
        <Col>
          <Title fontSize={32} color="#333">
            Our Advantages
          </Title>
        </Col>
      </Row>

      <Row
        className={isSmallScreen || !xl ? 'flex-col-reverse row-gap-12' : null}>
        <Col mt={2} v="center" cw={isSmallScreen ? 12 : null}>
          {advantagesConfig?.map(({ title, description, imageSrc }) => (
            <Row v="top">
              <Col mr={3} cw="auto">
                <Img width={40} src={imageSrc} py={1} />
              </Col>
              <Col mb={4} cw={7}>
                <Title fontSize={22} mt={0} color="#333">
                  {title}
                </Title>
                <Text style={{ maxWidth: '322px' }}>{description}</Text>
              </Col>
            </Row>
          ))}
        </Col>
        <Col h="center" v="center" cw={isSmallScreen ? 12 : null}>
          <Box>
            <Img width={'100%'} src={advertisement} />
          </Box>
        </Col>
      </Row>
    </Container>
  )
}

export default OurAdvantages
