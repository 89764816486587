import {
  Button,
  Card,
  Col,
  Container,
  Img,
  Row,
  Title
} from '@qonsoll/react-design'
import { CryptoCoupleList, CurrencySchedule } from './components'
import { Footer, Header, NavigationBar } from 'components'

import { Divider } from 'antd'
import { PopularCurrencyTable } from 'domains/PopularCurrency/components'
import RightSide from 'components/Exchange/RightSide'
import { cryptoPageData } from '__constants__'
import { useCurrencyContext } from 'contexts/CurrencyContext'
import { useCurrentScreen } from 'helpers'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

const CurrencyShow = () => {
  const location = useLocation()

  const { btc, eth, ltc, bnb, xrp, usdt } = useCurrencyContext()

  const currency = location?.pathname?.replace(/\//g, '')
  const currencyTitle =
    currency === 'bnb'
      ? 'Binance '
      : currency.charAt(0).toUpperCase() + currency.slice(1)

  const pageData = cryptoPageData[currency]
  const isBitcoin = currency === 'bitcoin'

  const { isSmallScreen, xs } = useCurrentScreen()

  const handleChangeClick = () => {
    let exchange = document.getElementById('exchange-anchor')
    exchange.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }

  const cryptoCoupleCol = useMemo(
    () => (isSmallScreen ? 12 : 6),
    [isSmallScreen]
  )

  const rightSideCol = useMemo(() => (xs ? 12 : 'auto'), [xs])

  const priceData = {
    bitcoin: btc?.price,
    tether: usdt?.price,
    bnb: bnb?.price,
    ethereum: eth?.price,
    litecoin: ltc?.price,
    ripple: xrp?.price
  }

  return (
    <Container style={{ width: isSmallScreen ? '100%' : '94%' }}>
      <Row>
        <Col cw={12}>
          <Header />
        </Col>
      </Row>
      <Row my={4}>
        <Col cw={12}>
          <NavigationBar />
        </Col>
      </Row>

      <Container style={{ width: isSmallScreen ? '95%' : '90%' }}>
        <Row v="center" mb={60}>
          <Col cw="auto">
            <Img
              height="56px"
              width="56px"
              src={pageData?.icon}
              alt={pageData?.mark}
            />
          </Col>
          <Col>
            <Title level={1} mt={0}>
              {pageData?.pageTitle}
            </Title>
          </Col>
        </Row>
        {/* Changer */}
        <Row mb={70} className="row-gap-12" h="center" v="center">
          <Col cw={cryptoCoupleCol}>
            <CurrencySchedule
              currentPrice={priceData[currency]}
              name={currency}
              pageData={pageData}
            />
          </Col>
          <Col cw={rightSideCol}>
            <Card
              id="exchange-anchor"
              className="flex align-center justify-center"
              style={{
                background: 'var(--ql-color-primary)',
                height: '100%'
              }}
              bodyStyle={{
                padding: xs ? '14px' : '24px'
              }}>
              <RightSide currency={currency} />
            </Card>
          </Col>
        </Row>
        <Row mb={30}>
          <Col>
            <Title level={3}>Crypto couples</Title>
          </Col>
        </Row>

        <CryptoCoupleList currency={currency} />

        {pageData?.text}
        <Row mb={40} h="between">
          <Col cw={xs ? 'auto' : 5}>
            <Button onClick={handleChangeClick}>
              Exchange {currencyTitle}
            </Button>
          </Col>
          <Col display={isBitcoin ? '' : 'none'} cw={5}>
            <Button
              onClick={() => window.open('https://bitcoin.org/ru/')}
              color="#535079"
              type="link">
              Official website
            </Button>
          </Col>
        </Row>
        <Row>
          <Col cw={12}>
            <PopularCurrencyTable />
          </Col>
        </Row>
        <Row mt={3}>
          <Col>
            <Divider />
          </Col>
        </Row>

        <Row mt={3}>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default CurrencyShow
