// import { Layout, LayoutSystemProvider } from '@qonsoll/react-design'

import { LayoutStyled } from './BoilerplateLayout.styled'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import breakpoints from '../../styles/breakpoints'

const BoilerplateLayout = ({ children }) => {
  /* If the user is authenticated, the component will render the children. Otherwise, it will render
  the fallback component. */

  const { Content } = LayoutStyled

  return (
    <ThemeProvider theme={breakpoints}>
      <LayoutStyled>
        <Content>{children}</Content>
      </LayoutStyled>
    </ThemeProvider>
    // children
  )
}

BoilerplateLayout.propTypes = {
  children: PropTypes.element
}

export default BoilerplateLayout
