import { Box, Col, Row, Text, Title } from '@qonsoll/react-design'
import { useHistory, useParams } from 'react-router-dom'

import PropTypes from 'prop-types'
// import { WebmoneyWidget } from 'components'
import { useGetExchangeBaseActions } from '../../hooks'

const Order = (props) => {
  const {
    handleNextStep,
    handlePrevStep,
    isCryptoReceiving,
    isCryptoDispatch
  } = props

  const { orderId } = useParams()
  const history = useHistory()

  const onSubmit = () => {
    if (isCryptoDispatch) return handleNextStep()
    if (!isCryptoDispatch) history.push('/payment-error')
  }

  const { submitButton, cancelButton } = useGetExchangeBaseActions({
    onCancel: handlePrevStep,
    onSubmit,
    submitText: 'Submit',
    cancelText: 'Cancel'
  })

  const orderData = localStorage.getItem(orderId)

  const { dispatch = {}, receiving = {}, wallet = '' } = JSON.parse(orderData)

  const isUsdt = receiving?.type === 'USDT'
  const isRub = receiving?.type === 'RUB'

  return (
    <Row>
      <Col cw={12}>
        <Title level={3} textAlign="center">
          {isCryptoReceiving ? '2. Your order' : '2. Payment'}
        </Title>
      </Col>
      <Col cw={12} mb={2}>
        <Text textAlign="center">
          Check the accuracy of the information in your order. After selecting
          the payment method, you will be redirected to the payment system's
          website.
        </Text>
      </Col>
      <Col cw={12} mb={4}>
        <Text type="warning" textAlign="center">
          Please note that orders placed outside of business hours may be
          processed with a delay.
        </Text>
      </Col>
      <Col cw={12} mb={4}>
        <Box
          background="var(--ql-color-secondary)"
          borderRadius="var(--card-radius)"
          p={3}>
          <Row
            noOuterGutters={!isCryptoReceiving}
            noGutters={isCryptoReceiving}>
            <Col cw="auto">
              <div>
                <Text variant="caption1" type="secondary">
                  Order ID
                </Text>
              </div>
              <div>
                <Text variant="body1">{orderId}</Text>
              </div>
            </Col>
            <Col cw={isCryptoReceiving ? 12 : 'auto'}>
              <div>
                <Text variant="caption1" type="secondary">
                  {isCryptoReceiving ? 'Receive to wallet' : 'Receive to card'}
                </Text>
              </div>
              <div>
                <Text variant="body1" style={{ wordBreak: 'break-word' }}>
                  {wallet}
                </Text>
              </div>
            </Col>
          </Row>
          <div
            style={{
              border: '1px dashed var(--ql-color-secondary-lighten1)',
              margin: '16px 0px'
            }}
          />
          <Row noGutters>
            <Col cw={12} mb={2}>
              <Row noGutters h="between" v="center">
                <Col cw="auto">
                  <Text variant="caption1" type="secondary">
                    Sending
                  </Text>
                </Col>
                <Col cw="auto">
                  <Text variant="body1">{`${dispatch.type} ${dispatch.sum}`}</Text>
                </Col>
              </Row>
            </Col>
            <Col cw={12}>
              <Row noGutters h="between" v="center">
                <Col cw="auto">
                  <Text variant="caption1" type="secondary">
                    Receiving
                  </Text>
                </Col>
                <Col cw="auto">
                  <Title color="var(--ql-color-accent-darken1)" level={4}>{`${
                    receiving?.type
                  } ${
                    isRub || isUsdt
                      ? receiving?.sum?.toFixed(2)
                      : receiving?.sum
                  }`}</Title>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Col>
      <Col cw={12}>
        <Row noGutters className="gap-12">
          <Col>{cancelButton}</Col>
          <Col>{submitButton}</Col>
        </Row>
      </Col>
    </Row>
  )
}

Order.propTypes = {
  handleNextStep: PropTypes.func,
  handlePrevStep: PropTypes.func,
  isCryptoReceiving: PropTypes.bool,
  isCryptoDispatch: PropTypes.bool
}

export default Order
