import {
  Box,
  Col,
  Container,
  Img,
  Row,
  Text,
  Title
} from '@qonsoll/react-design'

import advertisement from '../../assets/Advertisement2.png'
import { useCurrentScreen } from 'helpers'

const advantagesConfig = [
  {
    title: 'Convenient',
    description:
      'Intuitive interface and 4 simple steps of exchange. Buy, sell, and exchange cryptocurrency online anytime.',
    imageSrc:
      'https://changeit.com.ua/_ipx/f_webp,s_40x40/https://a.storyblok.com/f/120064/x/07931185cf/icon-1.svg'
  },
  {
    title: 'Profitable',
    description:
      'No hidden fees or additional payments. Make transactions at a favorable rate with any bank cards.',
    imageSrc:
      'https://changeit.com.ua/_ipx/f_webp,s_40x40/https://a.storyblok.com/f/120064/x/beadf8e121/icon-2.svg'
  },
  {
    title: 'Reliable',
    description:
      'We guarantee a safe and confidential exchange. Be assured that your data is protected, and transactions are under control.',
    imageSrc:
      'https://changeit.com.ua/_ipx/f_webp,s_40x40/https://a.storyblok.com/f/120064/x/b9cf680c7d/icon-3.svg'
  }
]

const Advanteges = () => {
  const { isSmallScreen, xl } = useCurrentScreen()

  return (
    <Container>
      <Row mb={5}>
        <Col>
          <Title fontSize={32} color="#333">
            Advantages of exchanging cryptocurrency with us
          </Title>
        </Col>
      </Row>

      <Row
        className={isSmallScreen || !xl ? 'flex-col-reverse row-gap-12' : null}>
        <Col mt={2} v="center" cw={isSmallScreen ? 12 : null}>
          {advantagesConfig?.map(({ title, description, imageSrc }, i) => (
            <Row v="top" key={`${title}${description}${i}`}>
              <Col mr={3} cw="auto">
                <Img width={40} src={imageSrc} py={1} />
              </Col>
              <Col mb={4} cw={7}>
                <Title fontSize={22} mt={0} color="#333">
                  {title}
                </Title>
                <Text style={{ maxWidth: '322px' }}>{description}</Text>
              </Col>
            </Row>
          ))}
        </Col>
        <Col h="center" v="center" cw={isSmallScreen ? 12 : null}>
          <Box>
            <Img width={'100%'} src={advertisement} />
          </Box>
        </Col>
      </Row>
    </Container>
  )
}

export default Advanteges
