import { Box, Img } from '@qonsoll/react-design'

import styled from 'styled-components'

const BoxStyled = styled(Box)`
  border-radius: 4px;
  position: relative;
  background: #f3f3f8;
  /* width: 384px; */
  height: 532px;
  /* padding: 52px 40px; */
  z-index: -2;
`
const ImgStyled = styled(Img)`
  position: absolute;
  object-fit: cover;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
`
export { BoxStyled, ImgStyled }
