const addThousandSeparators = (number) => {
  if (!number) return 0
  const transformedNumber = Number((+number)?.toFixed?.(9))

  const [integerNumber, fractionNumber] = transformedNumber
    .toString()
    .split('.')

  const transformedIntegerNumber = integerNumber.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ' '
  )

  return [transformedIntegerNumber, fractionNumber]?.filter(Boolean).join('.')
}

export default addThousandSeparators
