import {
  BinancecoinIcon,
  BitcoinIcon,
  EthereumIcon,
  LitecoinIcon,
  NotcoinIcon,
  RippleIcon,
  RubleIcon,
  TetherIcon,
  ToncoinIcon,
  UsdIcon
} from 'assets'
const CURRENCY = [
  {
    icon: <UsdIcon />,
    name: 'USD',
    key: 'USD',
    value: 'USD',
    isCrypto: false
  },
  {
    icon: <TetherIcon />,
    name: 'USDT',
    key: 'USDT',
    value: 'USDT',
    isCrypto: true
  },
  {
    icon: <BitcoinIcon />,
    name: 'BTC',
    key: 'BTC',
    value: 'BTC',
    isCrypto: true
  },
  {
    icon: <EthereumIcon />,
    name: 'ETH',
    key: 'ETH',
    value: 'ETH',
    isCrypto: true
  },
  {
    icon: <BinancecoinIcon />,
    name: 'BNB',
    key: 'BNB',
    value: 'BNB',
    isCrypto: true
  },
  {
    icon: <ToncoinIcon />,
    name: 'TON',
    key: 'TON',
    value: 'TON',
    isCrypto: true
  },
  {
    icon: <NotcoinIcon />,
    name: 'NOT',
    key: 'NOT',
    value: 'NOT',
    isCrypto: true
  },
  {
    icon: <RippleIcon />,
    name: 'XRP',
    key: 'XRP',
    value: 'XRP',
    isCrypto: true
  },
  {
    icon: <LitecoinIcon />,
    name: 'LTC',
    key: 'LTC',
    value: 'LTC',
    isCrypto: true
  },
  { icon: <RubleIcon />, name: 'RUB', key: 'RUB', value: 'RUB' }
]

export { CURRENCY }
