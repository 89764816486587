import { Col, Container, Row, Text, Title } from '@qonsoll/react-design'
import { addThousandSeparators, useCurrentScreen } from 'helpers'

import { useCurrencyContext } from 'contexts/CurrencyContext'
import { useMemo } from 'react'

const TransactionInfo = () => {
  const { isSmallScreen, xl } = useCurrentScreen()

  const { transactions, volume } = useCurrencyContext()

  const computePadding = useMemo(() => (isSmallScreen ? 2 : 4), [isSmallScreen])

  const textProps = useMemo(
    () => ({
      title: { level: isSmallScreen ? 3 : 2 },
      text: { variant: isSmallScreen ? 'caption2' : null }
    }),
    [isSmallScreen]
  )

  const transactionInfoMap = useMemo(
    () => [
      { title: '70 000+', subTitle: 'Total number of exchanges' },
      {
        title: addThousandSeparators(transactions),
        subTitle: 'Exchanges in 24 hours'
      },
      { title: '318 000 000+', subTitle: 'Total amount of exchanges' },
      {
        title: `${addThousandSeparators(volume)} $`,
        subTitle: 'Amount of exchanges in 24 hours'
      }
    ],
    [transactions, volume]
  )
  return (
    <Container>
      <Row p={computePadding} className=" flex justify-between  row-gap-12">
        {transactionInfoMap.map(({ title, subTitle }) => (
          <Col className="p-0" cw={isSmallScreen || !xl ? 6 : null} h="center">
            <Title {...textProps.title} mb={0}>
              {title}
            </Title>
            <Text {...textProps.text}>{subTitle}</Text>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default TransactionInfo
