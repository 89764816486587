import {
  Advanteges,
  Advertisment,
  Exchange,
  Footer,
  FrequentlyAskedQuestions,
  Header,
  NavigationBar,
  TransactionInfo,
  UserHistory,
  WhyWe
} from 'components'
import { Col, Container, Row } from '@qonsoll/react-design'

import { PopularCurrencyTable } from 'domains/PopularCurrency/components'
import { useCurrentScreen } from 'helpers'
import { useEffect } from 'react'
import { createDocument } from 'services/helpers'
import { v4 } from 'uuid'

const faq = [
  {
    header: 'What currencies does your exchange support?',
    description:
      'At the moment, on our exchange, you can buy/sell BTC, ETH, USDT, LTC, XRP, and BNB. We are also working on adding other currencies, which will be available for operations in the near future.'
  },
  {
    header:
      'Are there any hidden fees or other charges when using your exchange?',
    description:
      'No, you receive exactly the amount displayed in the "You Receive" field.'
  },
  {
    header: 'How to create a cryptocurrency wallet?',
    description: `The process of creating a cryptocurrency wallet depends entirely on the type of storage you choose. Not all of them can be "created" in the traditional sense.\nOnline Wallet: Simply register on the website of the respective service. The process is similar to registering for a regular online payment service.
    
    \nDesktop Wallet: Install the client on your PC. If necessary, perform synchronization.
    
    \nMobile Wallet: Download the wallet application to your smartphone or tablet with Android or iOS from the official resource or a third-party site.
    
    \nHardware Wallet: Order the device from the official manufacturer's website, make the payment, and wait for delivery.
    
    \nToday, there are many types of storage available, but not all are equally reliable. Experience shows that the security of a wallet is inversely proportional to its convenience.`
  }
  // {
  //   header:
  //     'Существуют ли скрытые комиссии или какие-либо другие платежи при пользовании вашим обменником?',
  //   description:
  //     'Вопрос легализации криптовалют в России рассматривался долго, и ожидаемым результатом стал перевод криптодеятельности в законное поле. Осенью 2021 года Госдума РФ поддержала законопроект, а в феврале текущего года был принят закон «О цифровых активах». Это привело к полной легализации операций с цифровыми активами. В России можно абсолютно законно покупать, продавать, обменивать крипту, открывать блокчейн-бизнесы, регистрировать криптосервисы и так далее. Это также привело к внедрению политики налогообложения на вывод цифровых монет. И несмотря на то, что в связи с полномасштабной войной НБУ ввел ограничения на покупку крипты, приобрести цифровые активы по-прежнему можно.'
  // }
]

const Dashboard = () => {
  const { isSmallScreen } = useCurrentScreen()

  useEffect(() => {
    // Перевіряємо, чи існує унікальний ідентифікатор користувача в localStorage
    let userId = localStorage.getItem('userId')

    if (!userId) {
      userId = v4()

      localStorage.setItem('userId', userId)

      const createUserInFirestore = async () => {
        await createDocument('users', { id: userId })
      }

      createUserInFirestore()
    }
  }, [])
  return (
    <Container style={{ width: isSmallScreen ? '100%' : '94%' }}>
      <Row>
        <Col cw={12}>
          <Header />
        </Col>
      </Row>
      <Row my={4}>
        <Col>
          <NavigationBar />
        </Col>
      </Row>

      <Row mb={4}>
        <Col cw={12}>
          <Exchange />
        </Col>
      </Row>
      <Container style={{ width: isSmallScreen ? '95%' : '90%' }}>
        <Row mb={5}>
          <Col className="p-0">
            <TransactionInfo />
          </Col>
        </Row>

        <Row mb={6}>
          <Col cw={12}>
            <PopularCurrencyTable />
          </Col>
        </Row>
        <Row mb={6}>
          <Col cw={12}>
            <Advertisment />
          </Col>
        </Row>
        <Row>
          <Col cw={12}>
            <Advanteges />
          </Col>
        </Row>
        {/* <Row mb={6}>
          <Col cw={12}>
            <UserHistory />
          </Col>
        </Row> */}
        <Row mb={6}>
          <Col cw={12}>
            <WhyWe />
          </Col>
        </Row>

        <Row mb={4}>
          <Col>
            <FrequentlyAskedQuestions faq={faq} />
          </Col>
        </Row>
        <Row mb={5}>
          <Col cw={12}>
            <Footer />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Dashboard
