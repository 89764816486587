import { Box, Col, Row, Text, Title } from '@qonsoll/react-design'
import { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { CRYPTO_WALLET_LENGTHS } from '__constants__'
import { CardNumberInput } from 'components'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import { useForm } from 'antd/lib/form/Form'
import { useGetExchangeBaseActions } from '../../hooks'
import { useGetWalletValidations } from './hooks'

const Wallet = (props) => {
  const { isCryptoReceiving, handleNextStep } = props
  const [form] = useForm()
  const { orderId } = useParams()
  const history = useHistory()

  const orderData = JSON.parse(localStorage.getItem(orderId))
  const receivingType = useMemo(
    () => orderData?.receiving?.type,
    [orderData?.receiving?.type]
  )

  const { validatorMinCreditCardCharacters, validatorMaxCreditCardCharacters } =
    useGetWalletValidations(receivingType, isCryptoReceiving)

  const { cancelButton, submitButton } = useGetExchangeBaseActions({
    onCancel: () => history.push('/dashboard')
  })

  const computedDescription = useMemo(
    () =>
      isCryptoReceiving
        ? 'Please provide the cryptocurrency wallet number for the transfer. Make sure the entered address is correct.'
        : 'Please provide the number of your card to which you will transfer the funds.',
    [isCryptoReceiving]
  )

  const computedWalletTitle = useMemo(
    () => (isCryptoReceiving ? `Wallet ${receivingType}` : 'Card number'),
    [receivingType]
  )

  const computedPlaceholder = useMemo(() => {
    const walletConfig = CRYPTO_WALLET_LENGTHS?.[receivingType]
    return isCryptoReceiving ? walletConfig?.placeholder : 'XXXX XXXX XXXX XXXX'
  }, [receivingType])

  const onFinish = (credentials) => {
    const stringifyData = JSON.stringify({
      ...orderData,
      wallet: credentials?.cardNumber
    })

    localStorage.setItem(orderId, stringifyData)
    handleNextStep()
  }

  useEffect(
    () => form.setFieldsValue({ cardNumber: orderData?.wallet ?? null }, form),
    [orderData?.wallet]
  )

  return (
    <Form form={form} onFinish={onFinish}>
      <Row h="center" noGutters>
        <Col cw={12}>
          <Title textAlign="center" level={3}>
            1. Where to receive?
          </Title>
        </Col>
        <Col cw={12} mb={4}>
          <Text textAlign="center">{computedDescription}</Text>
        </Col>
        <Col>
          <Box mb={2}>
            <Text variant={'body2'}>{computedWalletTitle}</Text>
          </Box>
          <Form.Item
            name={'cardNumber'}
            rules={[
              {
                required: true,
                message: `Please provide the number of your ${
                  isCryptoReceiving ? 'wallet' : 'card'
                }!`
              },
              validatorMinCreditCardCharacters(),
              validatorMaxCreditCardCharacters()
            ]}>
            <CardNumberInput
              placeholder={computedPlaceholder}
              inputMode={isCryptoReceiving ? null : 'numeric'}
              isNumber={!isCryptoReceiving}
            />
          </Form.Item>

          <Row noGutters className="gap-12">
            <Col>{cancelButton}</Col>
            <Col>{submitButton}</Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

Wallet.propTypes = {
  isCryptoReceiving: PropTypes.bool,
  handleNextStep: PropTypes.func,
  handlePrevStep: PropTypes.func
}

export default Wallet
