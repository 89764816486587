import { Col, Row } from '@qonsoll/react-design'
// import { coupleData } from '__constants__'
import { addThousandSeparators, useCurrentScreen } from 'helpers'

import { CryptoCouple } from 'components'
import PropTypes from 'prop-types'
import bnbIcon from 'assets/BNB-circle-icon.svg'
import { useGetCryptoPrice } from 'hooks'
import { useMemo } from 'react'
import xrpIcon from 'assets/xrp-logo-circle.png'

const CryptoCoupleList = (props) => {
  const { currency } = props
  const breakPoints = useCurrentScreen()

  // bitcoin
  const BTCETH = useGetCryptoPrice('ETH', 'BTC')
  const BTCUSDT = useGetCryptoPrice('BTC', 'USDT')
  const BTCLTC = useGetCryptoPrice('LTC', 'BTC')
  const BTCBNB = useGetCryptoPrice('BNB', 'BTC')
  const BTCXRP = useGetCryptoPrice('XRP', 'BTC')

  // etheruim
  const ETHUSDT = useGetCryptoPrice('ETH', 'USDT')
  const ETHLTC = useGetCryptoPrice('LTC', 'ETH')
  const ETHBNB = useGetCryptoPrice('BNB', 'ETH')
  const ETHXRP = useGetCryptoPrice('XRP', 'ETH')

  // bnb
  const BNBUSDT = useGetCryptoPrice('BNB', 'USDT')
  const BNBLTC = useGetCryptoPrice('LTC', 'BNB')
  const BNBXRP = useGetCryptoPrice('XRP', 'BNB')

  // usdt
  const USDTLTC = useGetCryptoPrice('LTC', 'USDT')
  const USDTXRP = useGetCryptoPrice('XRP', 'USDT')

  const cryptoCoupleData = {
    bitcoin: [
      {
        isMinus: true,
        firstIcon:
          'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        procent: '-0.38%',
        coupleName: 'BTCETH',
        coupleValue: addThousandSeparators(BTCETH?.price),
        secondName: 'ETH'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        procent: '0.06%',
        coupleName: 'BTCUSDT',
        coupleValue: addThousandSeparators(BTCUSDT?.price),
        secondName: 'USDT'
      },
      {
        isMinus: true,
        firstIcon:
          'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
        procent: '-1.92%',
        coupleName: 'BTCLTC',
        coupleValue: addThousandSeparators(BTCLTC?.price),
        secondName: 'LTC'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        secondIcon: bnbIcon,
        procent: '0.03%',
        coupleName: 'BTCBNB',
        coupleValue: addThousandSeparators(BTCBNB?.price),
        secondName: 'BNB'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        secondIcon: xrpIcon,
        procent: '0.03%',
        coupleName: 'BTCXRP',
        coupleValue: addThousandSeparators(BTCXRP?.price),
        secondName: 'XRP'
      }
    ],
    ethereum: [
      {
        isMinus: true,
        firstIcon:
          'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        procent: '-0.38%',
        coupleName: 'ETHBTC',
        coupleValue: addThousandSeparators(BTCETH?.price),
        secondName: 'ETH'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        procent: '0.06%',
        coupleName: 'ETHUSDT',
        coupleValue: addThousandSeparators(ETHUSDT?.price),
        secondName: 'USDT'
      },
      {
        isMinus: true,
        firstIcon:
          'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
        procent: '-1.92%',
        coupleName: 'ETHLTC',
        coupleValue: addThousandSeparators(ETHLTC?.price),
        secondName: 'LTC'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        secondIcon: bnbIcon,
        procent: '0.03%',
        coupleName: 'ETHBNB',
        coupleValue: addThousandSeparators(ETHBNB?.price),
        secondName: 'BNB'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        secondIcon: xrpIcon,
        procent: '0.03%',
        coupleName: 'ETHXRP',
        coupleValue: addThousandSeparators(ETHXRP?.price),
        secondName: 'XRP'
      }
    ],
    bnb: [
      {
        isMinus: true,
        firstIcon: bnbIcon,
        secondIcon:
          'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        procent: '-0.38%',
        coupleName: 'BNBBTC',
        coupleValue: addThousandSeparators(BTCBNB?.price),
        secondName: 'BTC'
      },
      {
        firstIcon: bnbIcon,
        secondIcon:
          'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        procent: '0.06%',
        coupleName: 'BNBUSDT',
        coupleValue: addThousandSeparators(BNBUSDT?.price),
        secondName: 'USDT'
      },
      {
        isMinus: true,
        firstIcon: bnbIcon,
        secondIcon:
          'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
        procent: '-1.92%',
        coupleName: 'BNBLTC',
        coupleValue: addThousandSeparators(BNBLTC?.price),
        secondName: 'LTC'
      },
      {
        firstIcon: bnbIcon,
        secondIcon:
          'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        procent: '0.03%',
        coupleName: 'BNBETH',
        coupleValue: addThousandSeparators(ETHBNB?.price),
        secondName: 'ETH'
      },
      {
        firstIcon: bnbIcon,
        secondIcon: xrpIcon,
        procent: '0.03%',
        coupleName: 'BNBXRP',
        coupleValue: addThousandSeparators(BNBXRP?.price),
        secondName: 'XRP'
      }
    ],
    tether: [
      {
        isMinus: true,
        firstIcon:
          'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        procent: '-0.38%',
        coupleName: 'USDTBTC',
        coupleValue: addThousandSeparators(BTCUSDT?.price),
        secondName: 'BTC'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        procent: '0.06%',
        coupleName: 'USDTETH',
        coupleValue: addThousandSeparators(ETHUSDT?.price),
        secondName: 'ETH'
      },
      {
        isMinus: true,
        firstIcon:
          'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
        procent: '-1.92%',
        coupleName: 'USDTLTC',
        coupleValue: addThousandSeparators(USDTLTC?.price),
        secondName: 'LTC'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        secondIcon: bnbIcon,
        procent: '0.03%',
        coupleName: 'USDTBNB',
        coupleValue: addThousandSeparators(BNBUSDT?.price),
        secondName: 'BNB'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        secondIcon: xrpIcon,
        procent: '0.03%',
        coupleName: 'USDTXRP',
        coupleValue: addThousandSeparators(USDTXRP?.price),
        secondName: 'XRP'
      }
    ],
    // litecoin
    litecoin: [
      {
        isMinus: true,
        firstIcon:
          'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        procent: '-0.38%',
        coupleName: 'LTCETH',
        coupleValue: addThousandSeparators(ETHLTC?.price),
        secondName: 'ETH'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        procent: '0.06%',
        coupleName: 'LTCUSDT',
        coupleValue: addThousandSeparators(USDTLTC?.price),
        secondName: 'USDT'
      },
      {
        isMinus: true,
        firstIcon:
          'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
        secondIcon:
          'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        procent: '-1.92%',
        coupleName: 'LTCBTC',
        coupleValue: addThousandSeparators(BTCLTC?.price),
        secondName: 'BTC'
      },
      {
        firstIcon:
          'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
        secondIcon: bnbIcon,
        procent: '0.03%',
        coupleName: 'LTCBNB',
        coupleValue: addThousandSeparators(BNBLTC?.price),
        secondName: 'BNB'
      }
      // {
      //   firstIcon:
      //     'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
      //   secondIcon: xrpIcon,
      //   procent: '0.03%',
      //   coupleName: 'LTCXRP',
      //   coupleValue: XRPLTC?.price,
      //   secondName: 'XRP'
      // }
    ],
    ripple: [
      {
        isMinus: true,
        firstIcon: xrpIcon,
        secondIcon:
          'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        procent: '-0.38%',
        coupleName: 'XRPETH',
        coupleValue: addThousandSeparators(ETHXRP?.price),
        secondName: 'ETH'
      },
      {
        firstIcon: xrpIcon,
        secondIcon:
          'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        procent: '0.06%',
        coupleName: 'XRPUSDT',
        coupleValue: addThousandSeparators(USDTXRP?.price),
        secondName: 'USDT'
      },
      // {
      //   isMinus: true,
      //   firstIcon: xrpIcon,
      //   secondIcon:
      //     'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
      //   procent: '-1.92%',
      //   coupleName: 'XRPLTC',
      //   coupleValue: XRPLTC?.price,
      //   secondName: 'LTC'
      // },
      {
        firstIcon: xrpIcon,
        secondIcon: bnbIcon,
        procent: '0.03%',
        coupleName: 'XRPBNB',
        coupleValue: addThousandSeparators(BNBXRP?.price),
        secondName: 'BNB'
      },
      {
        firstIcon: xrpIcon,
        secondIcon:
          'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        procent: '0.03%',
        coupleName: 'XRPBTC',
        coupleValue: addThousandSeparators(BTCXRP?.price),
        secondName: 'BTC'
      }
    ]
  }

  const computedLayout = useMemo(() => {
    const large = breakPoints.isLargeScreen ? 6 : null
    const small = breakPoints.isSmallScreen ? 12 : null

    return small || large || 'auto'
  }, [breakPoints])

  const cryptoCouplesMap = useMemo(
    () =>
      cryptoCoupleData[currency]?.map((item, index) => (
        <Col
          cw={computedLayout}
          key={`coupleName-${item?.coupleName}-${index}`}>
          <CryptoCouple item={item} />
        </Col>
      )),
    [cryptoCoupleData, breakPoints]
  )
  return (
    <Row mb={120} className="flex justify-between row-gap-16">
      {cryptoCouplesMap}
    </Row>
  )
}

CryptoCoupleList.propTypes = {
  currency: PropTypes.string
}

export default CryptoCoupleList
