const CITIES = [
  { label: 'Москва', value: 'moscov' },
  { label: 'Санкт-Петербург', value: 'sanctPetersburg' },
  { label: 'Новосибирск', value: 'novosibirsk' }
]

const CITIES_EXCHANGES = {
  moscov: [
    { value: 'leninskaya17b', label: 'ул. Ленинская 17Б' },
    { value: 'moscovCity', label: 'Москва-Сити' }
  ],
  sanctPetersburg: [
    {
      value: 'ligovskiPereuloc2',
      label: 'Лиговка, ул. Лиговский переулок, дом 2'
    },
    {
      value: 'scobelProspectDom12',
      label: 'Скобелевский, ул. Скобелевский проспект, дом 12'
    },
    {
      value: 'moscovProspectDom147',
      label: 'Благодатный, ул .Московский проспект, дом 147'
    },
    {
      value: 'efrimovaDom3A',
      label: 'Сенная, ул. Ефимова д. 3А'
    },
    {
      value: 'bigConushennaya2',
      label: 'ул. Большая Конюшенная, 2'
    }
  ],
  novosibirsk: [{ value: 'leninskaya17b', label: 'ул. Ленинская 17Б' }]
}

export { CITIES, CITIES_EXCHANGES }
