import React from 'react'

const ToncoinIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 -2 20 24"
      fill="#32C99C"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M3.30321 0.709562C1.49777 0.973448 0.429024 2.83327 1.07623 4.57401C1.1358 4.72298 1.39554 5.22098 1.66386 5.68051C1.92789 6.14017 2.44737 7.04245 2.8178 7.68514C3.18823 8.32783 3.75037 9.30668 4.06539 9.85576C4.92122 11.3454 8.20003 17.0739 9.14534 18.7295C9.71594 19.7254 10.031 20.2361 10.163 20.3681C10.3716 20.5766 10.5845 20.666 10.8869 20.666C11.368 20.666 11.6491 20.4446 12.0621 19.7297C12.2027 19.4828 12.5006 18.9764 12.7178 18.6019C12.935 18.2273 13.148 17.8613 13.1862 17.7932C13.2246 17.7209 13.8377 16.6696 14.5488 15.4524C15.2557 14.2351 16.4224 12.2349 17.1377 11.0049C17.8488 9.77491 18.6707 8.36617 18.9559 7.87662C19.2455 7.38303 19.6841 6.62972 19.9396 6.19554C20.7443 4.81658 20.8381 4.57401 20.8806 3.81224C20.9658 2.2843 20.0332 1.0287 18.5941 0.726592C18.33 0.671217 17.3208 0.662764 10.9167 0.666929C6.85867 0.671217 3.43522 0.688245 3.30321 0.709562ZM9.82233 9.15354C9.82233 12.6519 9.80958 15.5162 9.79255 15.5162C9.77122 15.5162 9.56688 15.1757 9.32835 14.7586C8.2341 12.8392 4.52959 6.37857 3.6311 4.8336C3.24363 4.16115 3.04347 3.77818 3.02643 3.6547C2.9796 3.32698 3.14986 3.00784 3.45226 2.87161C3.61835 2.79504 3.80136 2.79076 6.72665 2.79076H9.82233V9.15354ZM18.4194 2.93115C18.6409 3.08012 18.76 3.32269 18.7643 3.62492C18.7643 3.93132 18.7004 4.08458 18.2746 4.81229C18.066 5.1656 17.8617 5.5188 17.8148 5.59966C17.704 5.79543 16.448 7.95331 16.0817 8.57897C15.9242 8.84702 15.6857 9.25988 15.5495 9.49399C15.4132 9.72799 15.1748 10.1408 15.0173 10.409C14.8596 10.6772 14.6212 11.0899 14.4849 11.324C14.3487 11.5582 14.1102 11.971 13.9527 12.2391C13.578 12.8775 12.7774 14.248 12.6369 14.499C12.2836 15.1162 12.0109 15.5588 11.9854 15.5588C11.9641 15.5588 11.9514 12.686 11.9514 9.17473V2.79076L15.0939 2.79921L18.2364 2.81207L18.4194 2.93115Z" />
    </svg>
  )
}

export default ToncoinIcon
