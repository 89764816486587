/* eslint-disable no-console */
import CurrencyContext from './CurrencyContext'
import PropTypes from 'prop-types'
import { useGetCryptoPrice } from 'hooks'
import { useMemo } from 'react'

const CurrencyProvider = ({ children }) => {
  const btc = useGetCryptoPrice('BTC')
  const eth = useGetCryptoPrice('ETH')
  const usdt = useGetCryptoPrice('USDT', null)
  const ltc = useGetCryptoPrice('LTC')
  const bnb = useGetCryptoPrice('BNB')
  const xrp = useGetCryptoPrice('XRP')
  const ton = useGetCryptoPrice('TON')
  const not = useGetCryptoPrice('NOT')

  const currencies = [btc, eth, usdt, ltc, bnb, xrp, ton, not]

  const volume = currencies
    ?.map((item) => +item?.volume)
    ?.reduce((prev, curr) => prev + curr, 0)

  const transactions = currencies
    ?.map((item) => +item?.count)
    ?.reduce((prev, curr) => prev + curr, 0)

  const isLoading = useMemo(() => {
    const arr = [btc, eth, ltc, bnb, xrp, volume, transactions]
    console.log(arr?.filter(Boolean)?.length !== arr?.length)
    return arr?.filter(Boolean)?.length !== arr?.length
  }, [btc, eth, ltc, bnb, xrp, volume, transactions])

  return (
    <CurrencyContext.Provider
      value={{
        btc,
        eth,
        usdt,
        ltc,
        bnb,
        xrp,
        ton,
        not,
        volume,
        transactions,
        isLoading
      }}>
      {children}
    </CurrencyContext.Provider>
  )
}

CurrencyProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default CurrencyProvider
