import { Dropdown, Menu, MenuItem } from '@qonsoll/react-design'

import { Form } from 'antd'
import styled from 'styled-components'

const DropdownStyled = styled(Dropdown)`
  cursor: pointer;
  border: 1px solid var(--ql-color-primary-lighten2);
  border-radius: 4px;
  padding: ${({ isXs }) => `4px ${isXs ? '8px' : '12px'}`};
`
const MenuStyled = styled(Menu)`
  background-color: var(--ql-color-primary-lighten1);
  border: 1px solid var(--ql-color-primary-lighten2);
  margin-top: -2px;
  padding: 0px;
`
const MenuItemStyled = styled(MenuItem)`
  background-color: var(--ql-color-primary-darken1);
  color: #fff !important;
  padding: ${({ isXs }) => `4px ${isXs ? '8px' : '12px'}`};
  & .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
  }

  &:hover {
    background-color: var(--ql-color-primary-lighten1);
  }
`

const FormItemStyled = styled(Form.Item)`
  .ant-form-item-control-input {
    min-height: 0px;
  }
`

export { DropdownStyled, MenuItemStyled, MenuStyled, FormItemStyled }
