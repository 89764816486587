import React from 'react'

const TetherIcon = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.72053 2.82387L1.68032 9.20854C1.66816 9.23167 1.66398 9.25817 1.66841 9.28392C1.67284 9.30967 1.68564 9.33325 1.70483 9.35099L9.91166 17.219C9.93528 17.2403 9.96594 17.2521 9.99773 17.2521C10.0295 17.2521 10.0602 17.2403 10.0838 17.219L18.2955 9.35099C18.3145 9.33312 18.3272 9.30956 18.3316 9.28386C18.336 9.25816 18.332 9.23172 18.32 9.20854L15.2798 2.82387C15.2718 2.80166 15.2569 2.78257 15.2373 2.76931C15.2178 2.75606 15.1945 2.74932 15.1709 2.75005H4.82839C4.80508 2.75069 4.78244 2.75798 4.76314 2.77106C4.74384 2.78413 4.72868 2.80246 4.71944 2.82387H4.72053Z"
        fill="#32C99C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0564 9.86181C10.9976 9.86672 10.6931 9.88633 10.0151 9.88633C9.47503 9.88633 9.09181 9.87162 8.95426 9.86181C6.86707 9.76866 5.3102 9.40504 5.3102 8.97279C5.3102 8.54054 6.86707 8.17229 8.95426 8.07914V9.49847C9.09044 9.50827 9.47994 9.53279 10.02 9.53279C10.6683 9.53279 10.9924 9.50337 11.0515 9.49847V8.07914C13.1338 8.17229 14.6907 8.53591 14.6907 8.96789C14.6907 9.39987 13.1382 9.76866 11.0564 9.86181ZM11.0564 7.93669V6.66444H13.964V4.72461H6.04696V6.66444H8.95426V7.93669C6.59198 8.04564 4.81421 8.51112 4.81421 9.07602C4.81421 9.64092 6.59198 10.1026 8.95426 10.2154V14.287H11.0564V10.2154C13.4138 10.1064 15.1869 9.64065 15.1869 9.08092C15.1869 8.5212 13.4138 8.04455 11.0564 7.93696V7.93669Z"
        fill="white"
      />
    </svg>
  )
}

export default TetherIcon
