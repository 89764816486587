import { BoxStyled, ImgStyled } from './Advertisment.styled'
import { Col, Row, Title } from '@qonsoll/react-design'

import { useCurrentScreen } from 'helpers'

const Advertisment = () => {
  const advertisementData = [
    {
      img: 'https://a.storyblok.com/f/120064/x/78c3f3cbd4/info-card-bg-1.svg',
      title: 'Referral program',
      description:
        'Earn with us. Receive 1% of the transaction amount of each referred user.'
    },
    {
      img: 'https://a.storyblok.com/f/120064/x/4d3c4727ac/info-card-bg-2.svg',
      title: 'Personal account',
      description:
        'Register to get quick access to transaction history, bonuses, and our updates.'
    },
    {
      img: 'https://a.storyblok.com/f/120064/x/86639bd439/speed-main.svg',
      title: 'Quick exchange',
      description: 'No manual moderation, fully automated exchange process'
    }
  ]

  const { isSmallScreen } = useCurrentScreen()

  return (
    <Row h={isSmallScreen ? null : 'around'} v="center" className="row-gap-24">
      {advertisementData.map(({ title, description, img }, i) => (
        <Col cw={isSmallScreen ? 12 : 4} key={`${title}${description}${i}`}>
          <BoxStyled py={['52px']} px={['42px']}>
            <Row noGutters>
              <Col cw={12}>
                <Title fontSize={22}>{title}</Title>
              </Col>
            </Row>
            <Row noGutters>
              <Col>
                <Title lineHeight="24px" fontWeight={400} fontSize={14}>
                  {description}
                </Title>
              </Col>
            </Row>
            <Row v="bottom">
              <Col>
                <ImgStyled src={img} />
              </Col>
            </Row>
          </BoxStyled>
        </Col>
      ))}
    </Row>
  )
}

export default Advertisment
