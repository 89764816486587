import { addThousandSeparators, sortByAlphabet } from 'helpers'

import { PopularCurrencyTableTitle } from 'domains/PopularCurrency/components'
import { Typography } from 'antd'
import { useGetTableSearchProps } from 'hooks'
import { useMemo } from 'react'

const useGetProductsTableConfig = () => {
  /* Handling opening of the show page */

  const getColumnSearchProps = useGetTableSearchProps({
    placeholder: 'Search'
  })

  const columns = useMemo(
    () => [
      {
        title: 'Cryptocurrency',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => sortByAlphabet(a?.title, b?.title),
        ...getColumnSearchProps('title', ['title']),
        render: (_, record) => <PopularCurrencyTableTitle currency={record} />
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        sorter: (a, b) => a?.price - b?.price,
        render: (price) => (
          <Typography.Paragraph className="mb-0">
            {addThousandSeparators(price)}
          </Typography.Paragraph>
        )
      },
      {
        title: '% Price change',
        dataIndex: 'changePrice',
        key: 'changePrice',
        sorter: (a, b) => a?.changePrice - b?.changePrice,
        render: (changePrice) => (
          <Typography.Paragraph className="mb-0">
            {changePrice || 'n/a'}
          </Typography.Paragraph>
        )
      },
      {
        title: 'Transactions',
        dataIndex: 'transaction',
        key: 'transaction',
        sorter: (a, b) => a?.transaction - b?.transaction,
        render: (transaction) => (
          <Typography.Text>{transaction}</Typography.Text>
        )
      },
      {
        title: 'For the amount of',
        dataIndex: 'sum',
        key: 'sum',
        sorter: (a, b) => a?.sum - b?.sum,
        render: (sum) => (
          <Typography.Text>{addThousandSeparators(sum)}</Typography.Text>
        )
      }
    ],
    [getColumnSearchProps]
  )

  const tableProps = {
    scroll: { x: 1000 }
  }

  return {
    columns,
    tableProps
  }
}

export default useGetProductsTableConfig
