import BNB_QR from 'assets/QRS/BNB.png'
import BTC_QR from 'assets/QRS/BTC.png'
import ETH_QR from 'assets/QRS/ETH.png'
import LTC_QR from 'assets/QRS/LTC.png'
import NOT_QR from 'assets/QRS/NOT.png'
import TON_QR from 'assets/QRS/TON.png'
import USDTBEP20_QR from 'assets/QRS/USDTBEP20.png'
import USDTERC20_QR from 'assets/QRS/USDTERC20.png'
import USDTTRC20_QR from 'assets/QRS/USDTTRC20.png'
import XRP_QR from 'assets/QRS/XRP.png'

const WALLETS = {
  LTC: {
    qrCode: LTC_QR,
    walletNumber: 'ltc1qyd6e9sqzhnkz7su6457qrfaz9ng5p3kmcgujgs'
  },
  BTC: {
    qrCode: BTC_QR,
    walletNumber: 'bc1qrvactl3825aatytx2hhwn4xp25s2w5ct5qfmtz'
  },
  BNB: {
    qrCode: BNB_QR,
    walletNumber: '0xE9398E12A98E446C4BC12D45aEfB847928DA67DF'
  },
  ETH: {
    qrCode: ETH_QR,
    walletNumber: '0xE9398E12A98E446C4BC12D45aEfB847928DA67DF'
  },
  USDT: {
    qrCode: USDTTRC20_QR,
    walletNumber: 'TYPQSBSSQMF8JxqzypoNscTQ17i7at4XrG'
  },
  USDTTRC20: {
    qrCode: USDTTRC20_QR,
    walletNumber: 'TYPQSBSSQMF8JxqzypoNscTQ17i7at4XrG'
  },
  USDTBEP20: {
    qrCode: USDTBEP20_QR,
    walletNumber: '0xE9398E12A98E446C4BC12D45aEfB847928DA67DF'
  },
  USDTERC20: {
    qrCode: USDTERC20_QR,
    walletNumber: '0xE9398E12A98E446C4BC12D45aEfB847928DA67DF'
  },
  XRP: {
    qrCode: XRP_QR,
    walletNumber: 'rDGnwSHrNuTi6k9DKrYg56KKKjNLoxaYkn'
  },
  TON: {
    qrCode: TON_QR,
    walletNumber: 'UQBRcsdXZ_D3etLkdGxpNnWVlnjquTpPA-c-V6xmxRABtlZE'
  },
  NOT: {
    qrCode: NOT_QR,
    walletNumber: 'UQBRcsdXZ_D3etLkdGxpNnWVlnjquTpPA-c-V6xmxRABtlZE'
  }
}

export default WALLETS
