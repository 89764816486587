import React from 'react'

const NotcoinIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet">
      <path
        transform="rotate(180deg)"
        d="M18.4688 20.6225C20.2742 20.3586 21.3429 18.4988 20.6957 16.758C20.6362 16.6091 20.3764 16.1111 20.1081 15.6515C19.8441 15.1919 19.3246 14.2896 18.9542 13.6469C18.5837 13.0042 18.0216 12.0254 17.7066 11.4763C16.8508 9.98667 13.5719 4.25812 12.6266 2.60253C12.056 1.60664 11.741 1.0959 11.609 0.963957C11.4004 0.755446 11.1875 0.666016 10.8851 0.666016C10.4039 0.666016 10.1229 0.88739 9.70992 1.60236C9.56932 1.84921 9.27134 2.35567 9.05413 2.73018C8.83692 3.10469 8.62401 3.47075 8.58576 3.53887C8.5474 3.61115 7.93427 4.6624 7.2232 5.87966C6.5163 7.09691 5.34961 9.09713 4.63426 10.3271C3.92319 11.5571 3.10131 12.9659 2.81607 13.4554C2.52642 13.949 2.08784 14.7023 1.83239 15.1365C1.02768 16.5155 0.933904 16.758 0.89137 17.5198C0.806179 19.0477 1.73875 20.3033 3.17792 20.6054C3.44195 20.6608 4.45113 20.6693 10.8553 20.6651C14.9133 20.6608 18.3368 20.6438 18.4688 20.6225ZM11.9496 12.1785C11.9496 8.68011 11.9624 5.81583 11.9794 5.81583C12.0008 5.81583 12.2051 6.15628 12.4436 6.57343C13.5379 8.49279 17.2424 14.9535 18.1409 16.4984C18.5283 17.1709 18.7285 17.5538 18.7455 17.6773C18.7924 18.005 18.6221 18.3242 18.3197 18.4604C18.1536 18.537 17.9706 18.5413 15.0453 18.5413H11.9496L11.9496 12.1785ZM3.35259 18.4009C3.1311 18.2519 3.01195 18.0093 3.00766 17.7071C3.00766 17.4007 3.07152 17.2475 3.49736 16.5197C3.70598 16.1664 3.91032 15.8132 3.95714 15.7324C4.06795 15.5366 5.324 13.3787 5.69026 12.7531C5.84777 12.485 6.0863 12.0722 6.22248 11.838C6.35879 11.604 6.5972 11.1912 6.75471 10.923C6.91235 10.6548 7.15076 10.2421 7.28706 10.008C7.42325 9.77387 7.66178 9.36101 7.81929 9.09296C8.19401 8.45457 8.99456 7.08405 9.13503 6.83303C9.48842 6.21582 9.76103 5.7732 9.78653 5.7732C9.80786 5.7732 9.8206 8.64605 9.8206 12.1573L9.8206 18.5413L6.6781 18.5328L3.5356 18.52L3.35259 18.4009Z"
        fill="#32C99C"
      />
    </svg>
  )
}

export default NotcoinIcon
