import { Collapse } from 'antd'
import styled from 'styled-components'
const { Panel } = Collapse

const CollapseStyled = styled(Collapse)`
  border-left: none;
  border-right: none;
  position: relative;
  background-color: transparent;

  .ant-collapse-header {
    flex-direction: row-reverse;
    justify-content: space-between;
    display: flex !important;
    align-items: flex-start !important;
    padding-left: 0px !important;
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  .ant-collapse-expand-icon {
    padding-right: 4px;
  }
`
const PanelStyled = styled(Panel)`
  padding: 8px;
`

export { CollapseStyled, PanelStyled }
