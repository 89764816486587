import { CurrencyProvider } from 'contexts/CurrencyContext'
import Navigator from './pages/Navigator'

function App() {
  return (
    <CurrencyProvider>
      <Navigator />
    </CurrencyProvider>
  )
}

export default App
