import { CRYPTO_WALLET_LENGTHS } from '__constants__'

const useGetWalletValidations = (receivingType, isCrypto) => {
  const walletConfig = CRYPTO_WALLET_LENGTHS?.[receivingType]

  return {
    validatorMinCreditCardCharacters: () => {
      const condition = isCrypto ? walletConfig?.min : 16

      const computedMessage = isCrypto
        ? `Incorrect wallet number, minimum ${condition} characters`
        : 'Incorrect card number, minimum 16 characters'

      return {
        validator(_, value) {
          if (value?.replaceAll(' ', '')?.length < condition)
            return Promise.reject(new Error(computedMessage))

          return Promise.resolve()
        }
      }
    },

    validatorMaxCreditCardCharacters: () => {
      const condition = isCrypto ? walletConfig?.max : 19
      const computedMessage = isCrypto
        ? `Incorrect wallet number, maximum ${condition} characters`
        : 'Incorrect card number, maximum 19 characters'

      return {
        validator(_, value) {
          if (value?.replaceAll(' ', '')?.length > condition)
            return Promise.reject(new Error(computedMessage))

          return Promise.resolve()
        }
      }
    }
  }
}

export default useGetWalletValidations
