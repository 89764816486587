import PATHS from 'pages/paths'
import bnbLogo from 'assets/BNB-circle-icon.svg'
import notLogo from 'assets/not.svg'
import tonLogo from 'assets/ton.svg'
import { useCurrencyContext } from 'contexts/CurrencyContext'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import xrpLogo from 'assets/xrp-logo-circle.png'

const useGetPopularCurrency = () => {
  const history = useHistory()

  const { btc, ltc, eth, usdt, bnb, xrp, ton, not } = useCurrencyContext()

  return useMemo(
    () => [
      {
        icon: 'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
        title: ' Tether',
        price: usdt?.price,
        changePrice: usdt?.priceChange,
        transaction: usdt?.count,
        sum: usdt?.volume,
        subTitle: 'USDT',
        onCurrencyClick: () => history.push(PATHS.TETHER)
      },
      {
        icon: 'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
        title: 'Bitcoin',
        price: btc?.price,
        changePrice: btc?.priceChange,
        transaction: btc?.count,
        sum: btc?.volume,
        subTitle: 'BTC',
        onCurrencyClick: () => history.push(PATHS.BITCOIN)
      },
      {
        icon: 'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
        title: ' Ethereum',
        price: eth?.price,
        changePrice: eth?.priceChange,
        transaction: eth?.count,
        sum: eth?.volume,
        subTitle: 'ETH',
        onCurrencyClick: () => history.push(PATHS.ETHEREUM)
      },
      {
        icon: bnbLogo,
        title: 'Binance coin',
        price: bnb?.price,
        changePrice: bnb?.priceChange,
        transaction: bnb?.count,
        sum: bnb?.volume,
        subTitle: 'BNB',
        onCurrencyClick: () => history.push(PATHS.BNB)
      },
      {
        icon: tonLogo,
        title: 'Ton coin',
        price: ton?.price,
        changePrice: ton?.priceChange,
        transaction: ton?.count,
        sum: ton?.volume,
        subTitle: 'TON',
        onCurrencyClick: () => history.push(PATHS.XRP)
      },
      {
        icon: notLogo,
        title: 'Not coin',
        price: not?.price,
        changePrice: not?.priceChange,
        transaction: not?.count,
        sum: not?.volume,
        subTitle: 'NOT',
        onCurrencyClick: () => history.push(PATHS.XRP)
      },
      {
        icon: xrpLogo,
        title: 'Ripple',
        price: xrp?.price,
        changePrice: xrp?.priceChange,
        transaction: xrp?.count,
        sum: xrp?.volume,
        subTitle: 'XRP',
        onCurrencyClick: () => history.push(PATHS.XRP)
      },
      {
        icon: 'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
        title: ' Litecoin',
        price: ltc?.price,
        changePrice: ltc?.priceChange,
        transaction: ltc?.count,
        sum: ltc?.volume,
        subTitle: 'LTC',
        onCurrencyClick: () => history.push(PATHS.LITECOIN)
      }
    ],
    [btc, eth, usdt, ltc, bnb, xrp, ton, not]
  )
}

export default useGetPopularCurrency
