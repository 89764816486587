import { Card, Col, Row, Title } from '@qonsoll/react-design'
import {
  CategoryScale,
  Chart as ChartJS,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip
} from 'chart.js'
import { GroupStyled, LineStyled } from './CurrencySchedule.styled'
import { useCallback, useMemo } from 'react'

import PropTypes from 'prop-types'
import Zoom from 'chartjs-plugin-zoom'
import { addThousandSeparators } from 'helpers'
import moment from 'moment'
import { useGetCryptoPriceDuration } from 'hooks'

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Zoom
)

const CurrencySchedule = (props) => {
  const { pageData, name, currentPrice } = props
  const [currencyPriceDuration, period, setPeriod] = useGetCryptoPriceDuration(
    name,
    pageData?.mark
  )

  const times = useMemo(
    () =>
      currencyPriceDuration && currencyPriceDuration?.map((item) => item[0]),
    [currencyPriceDuration]
  )

  const prices = useMemo(
    () =>
      currencyPriceDuration && currencyPriceDuration?.map((item) => item[1]),
    [currencyPriceDuration]
  )

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        ticks: {
          display: false
        },

        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          display: false
        },
        border: {
          display: false
        },
        grid: {
          drawBorder: false,
          display: false
        }
      }
    },
    type: 'line',
    legend: {
      display: false
    },
    scaleShowLabels: false,

    interaction: {
      intersect: false,
      mode: 'index'
    },

    plugins: {
      customCanvasBackgroundColor: {
        color: '#fff'
      },
      tooltip: {
        callbacks: {
          title: function (arg) {
            const data = arg?.[0]
            const nativeDate = new Date(Number(data?.label))
            return moment(nativeDate).format('DD.MM.YYYY HH:mm')
          }
        }
      },

      zoom: {
        pan: { enabled: true, mode: 'x' },
        zoom: {
          wheel: {
            enabled: true
          },
          drag: {
            enabled: false
          },
          mode: 'x'
        }
      }
    },
    borderColor: '#32c99c',
    pointStyle: false
  }

  const radioOptions = useMemo(
    () => [
      {
        label: 'Year',
        value: 'year'
      },
      {
        label: 'Month',
        value: 'month'
      },
      {
        label: 'Week',
        value: 'week'
      },
      { label: 'Day', value: 'day' }
    ],
    []
  )

  const handleChangeRadioGroup = useCallback(
    ({ target }) => setPeriod(target?.value ?? 'week'),
    []
  )

  return (
    <Card
      style={{ boxShadow: '0 5px 15px #e2e6ee', height: '100%' }}
      bordered={false}>
      <Row v="center">
        <Col cw="auto" p={0}>
          <Title color="#4a4ad3">{addThousandSeparators(currentPrice)}</Title>
        </Col>

        <Col cw="auto">
          <Title mt={3} level={4} type="secondary">
            {pageData?.mark}/USD
          </Title>
        </Col>
        <Col cw="auto" ml="auto">
          {/* <Button onClick={handleResetZoom}>Reset zoom</Button> */}
          <GroupStyled
            optionType="button"
            buttonStyle="solid"
            value={period}
            options={radioOptions}
            onChange={handleChangeRadioGroup}
          />
        </Col>
      </Row>
      <Row>
        <Col cw={12}>
          <LineStyled
            options={options}
            datasetIdKey="id"
            data={{
              labels: times,
              datasets: [
                {
                  showLine: true,
                  data: prices
                }
              ]
            }}
          />
        </Col>
      </Row>
    </Card>
  )
}

CurrencySchedule.propTypes = {
  pageData: PropTypes.object,
  name: PropTypes.string,
  currentPrice: PropTypes.string
}

export default CurrencySchedule
