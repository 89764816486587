import { Box, Col, Container, Row, Title } from '@qonsoll/react-design'
import {
  Footer,
  FrequentlyAskedQuestions,
  Header,
  NavigationBar
} from 'components'

import { useCurrentScreen } from 'helpers'
import { useState } from 'react'

const FAQ = () => {
  const { isSmallScreen, xs, lg } = useCurrentScreen()
  const [selectedType, setSelectedType] = useState('all')

  const faqData = {
    all: [
      {
        header: 'What currencies does your exchange support?',
        description: `Currently, you can buy/sell BTC, ETH, USDT, LTC, Ripple, and BNB on our exchange. We are also working on adding other currencies, which will be available soon.`
      },
      {
        header: 'Is it profitable to buy Bitcoin right now?',
        description: `We do not advise buying or selling cryptocurrency, and we highlight that Bitcoin's price can fluctuate frequently due to spikes or drops in supply. We recommend not following various advertisements but making your own decision on whether to invest or not. Our task is to provide high-quality service for buying or selling Bitcoins and Ethereum.`
      },
      {
        header:
          'Are there any hidden fees or additional charges when using your exchange?',
        description: `No, you receive exactly what is shown in the "Receive" field..`
      },
      {
        header:
          'Are there additional costs when exchanging payment system tokens for rubles, aside from those listed in the rates?',
        description: `No, all costs are already included in the service fee.`
      },
      {
        header:
          'Is it possible to exchange an amount exceeding what is specified on the website?',
        description: `Yes, in this case, you need to contact our support team and discuss the details.`
      },
      {
        header:
          'What is the maximum and/or minimum amount I can specify in the application?',
        description:
          'The minimum amount is always shown in the field where you enter the amount of currency you are giving. If the amount exceeds the allowed transfer limits, you will receive a notification of exceeding the maximum limit per application. You can adjust the amount and create multiple applications. There is no limit to the number of applications.'
      },
      {
        header:
          'The Bitcoin transaction is taking a long time to confirm. What does this mean?',
        description: `Bitcoin transactions can often remain in an Unconfirmed Transaction state for a long time. This usually happens because one of the operations is taking a while to process. According to Bitcoin network rules, such a problematic transfer is attached to another block and then proceeds through the standard procedure. If this does not happen, the funds are returned to the sender within 1-7 days. Unfortunately, our exchange service cannot influence the speed of processing such transfers. If a payment is delayed for a prolonged period, once it is credited or returned to the sender, the situation with the request will be resolved either by completing or canceling it with the return of all financial obligations of each party. The decision is made on a case-by-case basis.`
      },
      {
        header: 'My question is not in the FAQ list. How can I get an answer?',
        description: `You can ask your question by contacting our support team via email or by filling out the form. We are always available. Responses are provided within 24 hours.`
      }
    ],
    security: [
      {
        header: 'Recommendations for Storing Cryptocurrencies',
        description: `Follow these recommendations to reduce the risk of losing funds due to hackers or malware. Remember that the most vulnerable part of the cryptocurrency system is you—the user. Hackers and scammers skillfully use their communication skills to gain your trust. Learn how cryptocurrencies work, what private keys are, and how to store them. Never perform transactions in haste—always check what and where you are entering: amounts and addresses. Do not share your private keys, passwords, or other important information with anyone. Use separate email addresses for registering on cryptocurrency exchanges and other services, and unique passwords for each. Yes, this may be inconvenient, but convenience and security are opposing factors: the more convenience, the less security. When creating a new cryptocurrency wallet on your devices, write down the password and seed phrase—this is effectively your money, as only the seed phrase can restore access to your wallet if you lose the device or experience a system failure. Store the seed and wallet password on another device in an encrypted form or on paper, ensuring 100% security of the storage and restricting access to others. For instance, a USB drive can be used and kept in a secure place to prevent others from taking it and rewriting its data. You are solely responsible for the safety of your funds.`
      },
      {
        header: 'Is it safe?',
        description: `Bitcoin is a secure technology based on data encryption and anonymity. Bitcoin coins are stored in a wallet file on your personal computer. You need to protect it from third-party access just like a physical wallet. The "Bitcoin" project is 5 years old, and no information security specialist has managed to hack it. From a technological perspective, the distributed Bitcoin system has advanced far beyond traditional banking processing centers. The Bitcoin operation principle is more similar to handling "cash" than using a payment card.`
      },
      {
        header: 'What are Bitcoin transactions?',
        description: `Each transaction includes the amount of tokens being transferred, the sender's input address, the recipient's output address, and a private key (known only to the sender and used in conjunction with the public key to create an unforgeable signature for the message). The user, holding the private key for their account, enters only the sender's address, after which the transaction is confirmed by miners. The security and reliability of this process are ensured by cryptographic algorithms. It is important to understand that the blockchain is a chain consisting of all transactions since the network's creation.`
      }
    ],
    wallet: [
      {
        header: 'Where can I create a Bitcoin wallet?',
        description:
          'You can create a Bitcoin wallet by either installing software on your personal computer or registering a wallet on a trusted and reliable service. The first option is recommended for those who are 100% confident in the security of their personal computer. To install a wallet on your PC, you need to download the latest version of the "Bitcoin Core" program. This program is currently one of the most reliable and user-friendly Bitcoin clients. The second option is more common and offers the benefit of easy and quick access from any device: PC, tablet, or smartphone. A popular and convenient service for creating a Bitcoin wallet is Blockchain.info.'
      },
      {
        header: 'What is a cryptocurrency account?',
        description: `A cryptocurrency account is usually referred to as a wallet. A wallet is a unique address represented by a random sequence of Latin characters and numbers. After generating this address, any Bitcoin user can send funds to it.`
      },
      {
        header: 'How many cryptocurrency accounts can I have?',
        description:
          'You can have an unlimited number of cryptocurrency accounts; the system does not limit the number of accounts for a single user.'
      },
      {
        header: 'How to create a crypto wallet?',
        description: `The steps to become the owner of a wallet depend directly on the type of client you choose to use. It is important to note that not all crypto wallets can be "created" in the traditional sense:
- Online Wallets - A simple registration on the website of a resource that provides digital money storage and usage is required. This type of wallet works similarly to traditional e-payment systems, with registration being similar to that of an online payment system.
- Desktop Wallets - You need to install the client on your PC. If required, you should also perform synchronization. Each cryptocurrency has its own wallet; for example, IOTA cannot be stored in Bitcoin Core.
- Hardware Wallets - You need to order them from the official manufacturer’s website, make a payment, and wait for delivery. Due to high demand, many companies only ship after a certain period, which can be clarified on the official wallet's website. There is no shortage of crypto wallets today, so you will definitely find a client that suits your personal preferences. However, do not expect any wallet to be perfect - it does not exist, and as a rule, the security of the client is inversely proportional to its convenience.`
      },
      {
        header: 'What happens if I lose my wallet key?',
        description: `The private key is unique to your Bitcoin wallet, and it can only be recovered using a secret phrase (Passcode seed) provided during registration. Some services offer backup options for accounts.`
      }
    ]
  }

  return (
    <Container
      style={{
        width: isSmallScreen ? '100%' : '94%',
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Row>
        <Col cw={12}>
          <Header />
        </Col>
      </Row>
      <Row mb={5} mt={4}>
        <Col cw={12}>
          <NavigationBar />
        </Col>
      </Row>
      <Container
        style={{
          width: isSmallScreen ? '95%' : '90%',
          flex: '1 0 auto',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <Row mb={5}>
          <Col>
            <Title level={2}>FAQ</Title>
          </Col>
        </Row>
        <Row h="center" style={{ flex: '1 0 auto' }}>
          <Col cw={xs ? 12 : lg ? 3 : 4} className={xs ? 'mb-24' : null}>
            <Box className="flex align-center">
              <Box
                style={{
                  height: '48px',
                  width: '6px',
                  background: selectedType === 'all' ? '#32c99c' : ''
                }}
              />
              <Title
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedType('all')}
                color={selectedType === 'all' ? '#32c99c' : ''}
                className="ml-24"
                level={isSmallScreen ? 5 : 4}>
                General
              </Title>
            </Box>

            <Box className="flex align-center">
              <Box
                style={{
                  height: '48px',
                  width: '6px',
                  background: selectedType === 'wallet' ? '#32c99c' : ''
                }}
              />
              <Title
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedType('wallet')}
                color={selectedType === 'wallet' ? '#32c99c' : ''}
                className="ml-24"
                level={isSmallScreen ? 5 : 4}>
                Web Wallet
              </Title>
            </Box>

            <Box className="flex align-center">
              <Box
                style={{
                  height: '48px',
                  width: '6px',
                  background: selectedType === 'security' ? '#32c99c' : ''
                }}
              />
              <Title
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedType('security')}
                color={selectedType === 'security' ? '#32c99c' : ''}
                className="ml-24"
                level={isSmallScreen ? 5 : 4}>
                Security
              </Title>
            </Box>
          </Col>
          <Col cw={xs ? 12 : lg ? 9 : 8}>
            <FrequentlyAskedQuestions
              withTitle={false}
              faq={faqData[selectedType]}
            />
          </Col>
        </Row>

        <Row mt={3}>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default FAQ
