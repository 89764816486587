const PATHS = {
  DASHBOARD: '/dashboard',
  EXCHANGE: '/exchange/:orderId',
  BITCOIN: '/bitcoin',
  ETHEREUM: '/ethereum',
  TETHER: '/tether',
  LITECOIN: '/litecoin',
  BNB: '/bnb',
  XRP: '/ripple',
  ABOUT_US: '/about-us',
  F_A_Q: '/frequently-asked-questions',
  PAYMENT_ERROR: '/payment-error'
}

export default PATHS
