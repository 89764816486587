import { Col, Container, Img, Row, Text, Title } from '@qonsoll/react-design'

import image from 'assets/cryptoIcon1.svg'
import { useCurrentScreen } from 'helpers'

const adavantages = [
  {
    subtitle: `Narrow specialization. `,
    title: ` The Opt Change online crypto exchange only trades a limited number of currencies, allowing it to offer the best rates on the market.`
  },
  {
    subtitle: 'No hidden fees. ',
    title: ' The amount on the screen is the actual amount the user will pay.'
  },
  {
    subtitle: 'Convenient and simple interface. ',
    title:
      'The service offers a clear and quick exchange process: 4 simple steps, and the transaction is complete.'
  },
  {
    subtitle: 'Beneficial conditions. ',
    title:
      'Opt Change always provides good rates, low fees, no risks, and a transparent operating algorithm.'
  }
]

const WhyWe = () => {
  const { isSmallScreen } = useCurrentScreen()

  return (
    <Container>
      <Row v="center" className="row-gap-12">
        <Col>
          <Row>
            <Col>
              <Title
                level={isSmallScreen ? 3 : 2}
                mb={4}
                fontWeight="600"
                strong>
                Why choose our online cryptocurrency exchange
              </Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text>
                Opt Change is a reliable and simple way to conduct
                cryptocurrency transactions. The service has an impeccable
                reputation and offers the best exchange conditions. If you
                decide to buy cryptocurrency online on the Opt Change platform,
                you will receive professional and prompt support, security, and
                simplicity.
              </Text>
            </Col>
          </Row>
          <Row my={3}>
            <Col>
              <Text strong>
                Other advantages that set the platform apart from its
                counterparts:
              </Text>
            </Col>
          </Row>
          <Row>
            {adavantages?.map(({ subtitle, title }) => (
              <Col cw={12}>
                <ul className="pl-24">
                  <li>
                    <Text>
                      <Text bold strong>
                        {subtitle}
                      </Text>
                      {title}
                    </Text>
                  </li>
                </ul>
              </Col>
            ))}
          </Row>
        </Col>
        {isSmallScreen ? null : (
          <Col cw={12} h="center">
            <Img width={'40%'} src={image} />
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default WhyWe
