import { Grid } from 'antd'
import { useMemo } from 'react'

const useCurrentScreen = () => {
  const { useBreakpoint } = Grid

  const breakPoints = useBreakpoint()
  const isSmallScreen = (breakPoints.xs || breakPoints.sm) && !breakPoints.md
  const isLargeScreen = breakPoints.md && !breakPoints.xxl

  return useMemo(
    () => ({ isSmallScreen, isLargeScreen, ...breakPoints }),
    [breakPoints, isSmallScreen, isLargeScreen]
  )
}

export default useCurrentScreen
