import { BoxStyled, ImgStyled } from './Advantages.styled'
import { Col, Row, Title } from '@qonsoll/react-design'

import { useCurrentScreen } from 'helpers'

const Advantages = () => {
  const advantagesData = [
    {
      img: 'https://a.storyblok.com/f/120064/x/d7d02129d5/iluustration-1.svg',
      title: 'Fees and Limits',
      description:
        'For your convenience, we display the final transaction amount in the exchange form. Detailed information about deposit/withdrawal fees and daily and monthly limits is available on a separate page.'
    },
    {
      img: 'https://a.storyblok.com/f/120064/x/2781a0578d/iluustration-2.svg',
      title: 'Our Reputation',
      description:
        'Our rating, based on customer reviews, speaks louder than a thousand marketing words. We value every piece of feedback, as it helps us grow and improve continuously. Join us and experience the benefits of working with a reputable and trustworthy cryptocurrency exchange.'
    },
    {
      img: 'https://a.storyblok.com/f/120064/x/c65e4f4f4f/frame-512765.svg',
      title: 'Have a Suggestion?',
      description:
        'We are always open to new ideas and collaborations. If you have a proposal or are interested in a partnership on franchise terms, feel free to reach out to us. We value your input and look forward to working together.'
    }
  ]

  const { isSmallScreen } = useCurrentScreen()

  return (
    <Row h={isSmallScreen ? null : 'around'} v="center" className="row-gap-24">
      {advantagesData.map(({ title, description, img }) => (
        <Col cw={isSmallScreen ? 12 : 4}>
          <BoxStyled py={['52px']} px={['42px']}>
            <Row noGutters>
              <Col cw={12}>
                <Title fontSize={22}>{title}</Title>
              </Col>
            </Row>
            <Row noGutters>
              <Col>
                <Title lineHeight="24px" fontWeight={400} fontSize={14}>
                  {description}
                </Title>
              </Col>
            </Row>
            <Row v="bottom">
              <Col>
                <ImgStyled src={img} />
              </Col>
            </Row>
          </BoxStyled>
        </Col>
      ))}
    </Row>
  )
}

export default Advantages
