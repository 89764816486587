import { Box, Title } from '@qonsoll/react-design'
import {
  DropdownStyled,
  FormItemStyled,
  MenuItemStyled,
  MenuStyled
} from './CurrencySelect.styled'
import { Form, Select } from 'antd'
import { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useCurrentScreen } from 'helpers'

const boxProps = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  v: 'center',
  h: 'center'
}

const CurrencySelect = (props) => {
  const { item, onChange, value, changeType, form } = props

  const [currentCurrency, setCurrentCurrency] = useState(null)

  const { xs } = useCurrentScreen()

  const handleClickItem = (item) => {
    value?.includes(item?.key) ? onChange(value) : onChange(item?.key)
    setCurrentCurrency(item?.split?.('-')?.[0] || item)
  }

  useEffect(() => {
    const computedItem = item?.find((val) => val?.key === value)

    if (computedItem) handleClickItem(computedItem)
  }, [value])

  const computedData = useMemo(
    () =>
      currentCurrency
        ? { icon: currentCurrency?.icon, name: currentCurrency?.name }
        : { icon: item?.[0]?.icon, name: item?.[0]?.name },
    [currentCurrency, item]
  )

  const titleProps = {
    fontWeight: 500,
    level: 5,
    mb: 0,
    ml: 2,
    color: 'inherit'
  }

  const dropdownItemList = useMemo(
    () => (
      <MenuStyled>
        {item
          ?.filter(({ key }) => changeType !== key)
          ?.map(({ name, icon, key }, i) => (
            <MenuItemStyled
              key={`${name}${key}${i}`}
              isXs={xs}
              onClick={() => handleClickItem({ name, icon, key })}>
              {icon}
              <Title {...titleProps}>{name}</Title>
            </MenuItemStyled>
          ))}
      </MenuStyled>
    ),
    [item, changeType]
  )

  const handleChangeStableCoin = (value) => {
    form.setFieldValue('stable', value)
  }

  const stablecoinsSelect = useMemo(
    () => (
      <FormItemStyled name={['stable']}>
        <Select
          className="stableSelect"
          size="small"
          onClick={(e) => e.stopPropagation()}
          defaultValue="ERC20"
          style={{ width: 'min-content', fontSize: '8px' }}
          onChange={handleChangeStableCoin}
          options={[
            {
              label: 'ERC20',
              value: 'ERC20'
            },
            {
              label: 'TRC20',
              value: 'TRC20'
            },
            {
              label: 'BEP20',
              value: 'BEP20'
            }
          ]}
        />
      </FormItemStyled>
    ),
    []
  )
  return (
    <DropdownStyled overlay={dropdownItemList} trigger="click" isXs={xs}>
      <Box {...boxProps} justifyContent="space-between">
        <Box {...boxProps}>
          {computedData?.icon}
          <Title {...titleProps}>{computedData?.name}</Title>
        </Box>
        {computedData?.name?.includes?.('USDT') ? stablecoinsSelect : null}
      </Box>
    </DropdownStyled>
  )
}

CurrencySelect.propTypes = {
  item: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
  changeType: PropTypes.string,
  form: PropTypes.object
}

export default CurrencySelect
