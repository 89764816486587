import { ImageStyled, styles } from './LoadingScreen.styles'

import React from 'react'
import logo from 'assets/optСhange3.png'

const LoadingScreen = () => {
  return (
    <div style={styles.imageWrapper}>
      <ImageStyled src={logo} alt="logo" />
    </div>
  )
}

export default LoadingScreen
