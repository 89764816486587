import { Box, Img, Text } from '@qonsoll/react-design'
import { Col, Dropdown, Row } from 'antd'
import { ItemStyled, MenuItemStyled, MenuStyled } from './NavigationBar.styled'

import PATHS from 'pages/paths'
import React from 'react'
import optСhange3 from '../../assets/optСhange3.png'
import { useCurrentScreen } from 'helpers'
import { useHistory } from 'react-router-dom'

const DROPDOWN_ITEM_MAP = {
  exchangeRates: [
    {
      name: 'Bitcoin',
      path: PATHS.BITCOIN
    },
    { name: 'Ethereum', path: PATHS.ETHEREUM },
    {
      name: 'Tether',
      path: PATHS.TETHER
    },
    {
      name: 'Litecoin',
      path: PATHS.LITECOIN
    },
    {
      name: 'Binance',
      path: PATHS.BNB
    },
    {
      name: 'Ripple',
      path: PATHS.XRP
    }
  ],

  exchange: [
    {
      name: 'Buy Bitcoin ',
      path: PATHS.BITCOIN
    },
    {
      name: 'Buy USDT',
      path: PATHS.TETHER
    },
    {
      name: 'Buy Binance',
      path: PATHS.BNB
    },
    { name: 'Buy Etherium', path: PATHS.ETHEREUM },
    { name: 'Buy Ripple', path: PATHS.XRP },
    { name: 'Buy Litecoin', path: PATHS.LITECOIN }
  ],
  aboutUs: [
    {
      name: 'Our crypto exchange',
      path: PATHS.ABOUT_US
    },
    {
      name: 'FAQ',
      path: PATHS.F_A_Q
    }
  ]
}

const NavigationBar = () => {
  const history = useHistory()

  const handleOpen = (path) => (e) => {
    history.push(path)
  }

  const { xs } = useCurrentScreen()
  const NAVIGATION_MAP = [
    {
      name: 'Currency rates',
      dropdownItem: (
        <MenuStyled>
          {DROPDOWN_ITEM_MAP.exchangeRates.map((item) => (
            <MenuItemStyled onClick={handleOpen(item?.path)}>
              {item?.name}
            </MenuItemStyled>
          ))}
        </MenuStyled>
      )
    },
    // {
    //   name: 'Обмен',
    //   dropdownItem: (
    //     <MenuStyled>
    //       {DROPDOWN_ITEM_MAP.exchange.map((item) => (
    //         <MenuItemStyled onClick={handleOpen(item?.path)}>
    //           {item?.name}
    //         </MenuItemStyled>
    //       ))}
    //     </MenuStyled>
    //   )
    // },
    {
      name: 'About us',
      dropdownItem: (
        <MenuStyled>
          {DROPDOWN_ITEM_MAP.aboutUs.map((item) => (
            <MenuItemStyled onClick={handleOpen(item?.path)}>
              {item?.name}
            </MenuItemStyled>
          ))}
        </MenuStyled>
      )
    }
  ]
  return (
    <Row gutter={32} className="overflow-hidden m-0 flex align-center">
      <Col className="p-0 mr-12">
        <Img
          style={{ cursor: 'pointer' }}
          onClick={handleOpen(PATHS.DASHBOARD)}
          preview={false}
          width={'72px'}
          src={optСhange3}
        />
      </Col>
      {NAVIGATION_MAP.map(({ dropdownItem, name }) => (
        <Col className="p-0">
          <Dropdown
            onClick={() => history.push(dropdownItem?.exchangeRates?.path)}
            arrow={true}
            overlay={dropdownItem}>
            <ItemStyled>
              <Text style={{ cursor: 'pointer' }} variant={xs ? 'body2' : null}>
                {name}
              </Text>
            </ItemStyled>
          </Dropdown>
        </Col>
      ))}
    </Row>
  )
}

export default NavigationBar
