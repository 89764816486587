import React from 'react'

const EthereumIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1044 1.66675V7.80169C10.1044 7.82774 10.0784 7.85378 10.0523 7.85378C9.87001 7.9334 9.71375 8.01227 9.53145 8.09114C9.29707 8.19606 9.03664 8.30172 8.80225 8.43342L7.94284 8.82778L7.23968 9.14402L6.38027 9.53912C6.14588 9.64478 5.9115 9.7497 5.65107 9.8814C5.46877 9.96027 5.26043 10.0659 5.07813 10.1448C5.05209 10.1448 5.05209 10.1708 5.02604 10.1448H5C5.07813 10.0131 5.15626 9.90744 5.23439 9.77649C5.65107 9.06514 6.0938 8.35455 6.51048 7.64321C6.95321 6.88052 7.42198 6.11635 7.86471 5.35292C8.2814 4.64233 8.69808 3.93173 9.11477 3.24718C9.42728 2.71962 9.7398 2.22034 10.0263 1.69279C10.0784 1.69279 10.0784 1.66675 10.1044 1.66675C10.0784 1.66675 10.1044 1.66675 10.1044 1.66675V1.66675Z"
        fill="#65EDC4"
      />
      <path
        d="M15.1567 10.1449C14.766 10.4084 14.3493 10.645 13.9587 10.8816C12.6826 11.6458 11.4325 12.3831 10.1564 13.1466C10.1304 13.1466 10.1304 13.1726 10.1044 13.1726C10.0783 13.1726 10.0783 13.1466 10.0783 13.1466V7.85392C10.0783 7.82788 10.1044 7.82788 10.1304 7.82788C10.2346 7.87997 10.3387 7.93354 10.469 7.98563C10.7815 8.14412 11.12 8.27582 11.4325 8.43356C11.719 8.56527 11.9794 8.69697 12.2659 8.80188C12.5524 8.93358 12.8128 9.06529 13.0993 9.19699C13.3337 9.30265 13.5941 9.40756 13.8285 9.53927C14.0629 9.64493 14.3233 9.74984 14.5577 9.88154C14.74 9.96042 14.9223 10.0661 15.1306 10.1449C15.1306 10.1189 15.1306 10.1449 15.1567 10.1449V10.1449Z"
        fill="#31997A"
      />
      <path
        d="M10.1044 18.3333C10.1044 18.3333 10.0784 18.3333 10.1044 18.3333C10.0784 18.3333 10.0784 18.3333 10.0523 18.3065C9.53147 17.5699 9.03666 16.8586 8.5158 16.1212L6.95323 13.9098C6.45842 13.1992 5.93756 12.4886 5.44275 11.7504L5.0521 11.1976C5.0521 11.1716 5.02606 11.1716 5.02606 11.1455C5.0521 11.1455 5.0521 11.1716 5.07815 11.1716C5.7813 11.5927 6.5105 12.0139 7.21366 12.435C8.02099 12.9358 8.85436 13.4097 9.66169 13.9098C9.7919 13.9886 9.94816 14.0675 10.0784 14.1464C10.1044 14.1464 10.1044 14.1732 10.1044 14.1992V18.3333Z"
        fill="#65EDC4"
      />
      <path
        d="M5 9.86915C5 9.84311 5 9.84311 5 9.86915C5.26043 9.73745 5.52086 9.63254 5.78128 9.50083L6.79696 9.02685C7.05738 8.89515 7.31781 8.79024 7.57824 8.65853C7.94284 8.474 8.33348 8.31626 8.69808 8.13172C8.95851 8.02607 9.21894 7.89436 9.47937 7.78945C9.66167 7.71057 9.84397 7.6317 10.0263 7.52604C10.0523 7.52604 10.0523 7.5 10.0784 7.5V12.8708C10.0523 12.8976 10.0523 12.8708 10.0263 12.8708C9.94814 12.8187 9.87001 12.7919 9.81792 12.7391L5.05209 9.89594C5.02604 9.86915 5 9.86915 5 9.86915ZM15.0786 10.8439C15.0786 10.8699 15.0786 10.8699 15.0525 10.896C13.542 13.0553 12.0316 15.1878 10.5211 17.3472C10.3648 17.5838 10.2086 17.7944 10.0523 18.0317V13.8448C10.3909 13.6342 10.7294 13.4236 11.068 13.2398L15.0525 10.8699C15.0525 10.8439 15.0786 10.8439 15.0786 10.8439V10.8439Z"
        fill="#32C99C"
      />
      <path
        d="M10.1044 7.82779V1.69434L15.1046 10.0667C15.1306 10.0928 15.1567 10.1188 15.1567 10.1456C15.0525 10.0935 14.9483 10.0399 14.8181 9.98786C14.6879 9.93503 14.5577 9.85616 14.4275 9.80333C14.3494 9.77729 14.2712 9.72446 14.1671 9.69767C14.0368 9.64558 13.8806 9.56597 13.7504 9.51388C13.6722 9.4871 13.5941 9.43501 13.516 9.40822L12.9691 9.1716C12.8649 9.11878 12.7868 9.09273 12.6826 9.0399C12.5524 8.98707 12.4222 8.9082 12.292 8.85537C12.2138 8.82933 12.1357 8.7765 12.0576 8.75046L11.5107 8.51309C11.4065 8.46101 11.3284 8.43422 11.2242 8.38139C11.094 8.32931 10.9638 8.24969 10.8336 8.19686C10.7554 8.14477 10.6513 8.11799 10.5731 8.0659L10.1044 7.82854V7.82779Z"
        fill="#32C99C"
      />
    </svg>
  )
}

export default EthereumIcon
