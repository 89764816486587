import { Box, Img } from '@qonsoll/react-design'

import { Form } from 'antd'
import homeBg from 'assets/home-bg.png'
import styled from 'styled-components'

const BoxStyled = styled(Box)`
  background-image: url(${homeBg});
  background-size: cover;
  border-radius: 6px;
`

const ImgStyled = styled(Img)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
  top: 0;
  left: 0;
`

const FormStyled = styled(Form)`
  * {
    color: #fff !important;
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  input {
    background-color: #fff;
    color: var(--ql-color-primary-lighten2) !important;
    font-weight: 500;
  }
`

export { ImgStyled, BoxStyled, FormStyled }
