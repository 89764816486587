import { AboutUs } from './AboutUs'
import { CurrencyShow } from './CurrencyShow'
import { Dashboard } from './Dashboard'
import { Exchange } from './Exchange'
import { FAQ } from './FAQ'
import PATHS from '../paths'
import { PaymentError } from './PaymentError'

const {
  EXCHANGE,
  DASHBOARD,
  BITCOIN,
  ETHEREUM,
  TETHER,
  LITECOIN,
  DOGECOIN,
  DASCOIN,
  F_A_Q,
  ABOUT_US,
  PAYMENT_ERROR
} = PATHS

const ROUTES = [
  {
    key: PAYMENT_ERROR,
    path: PAYMENT_ERROR,
    component: PaymentError,
    exact: true
  },
  {
    key: F_A_Q,
    path: F_A_Q,
    component: FAQ,
    exact: true
  },
  {
    key: ABOUT_US,
    path: ABOUT_US,
    component: AboutUs,
    exact: true
  },

  { key: EXCHANGE, path: EXCHANGE, component: Exchange, exact: true },
  { key: DASHBOARD, path: DASHBOARD, component: Dashboard, exact: true },
  {
    key: BITCOIN,
    path: BITCOIN,
    component: CurrencyShow,
    exact: true
  },
  {
    key: ETHEREUM,
    path: ETHEREUM,
    component: CurrencyShow,
    exact: true
  },
  {
    key: TETHER,
    path: TETHER,
    component: CurrencyShow,
    exact: true
  },
  {
    key: LITECOIN,
    path: LITECOIN,
    component: CurrencyShow,
    exact: true
  },
  {
    key: DOGECOIN,
    path: DOGECOIN,
    component: CurrencyShow,
    exact: true
  },
  {
    key: DASCOIN,
    path: DASCOIN,
    component: CurrencyShow,
    exact: true
  }
]

export { ROUTES }
export default ROUTES
