import moment from 'moment'

const formatDate = (timestamp) => moment.unix(timestamp).format('HH:mm')

const dateParams = (timestamp) => {
  const date = new Date(timestamp * 1000)
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  return [hours, minutes, seconds]
}

const generateDateArray = (momentDate) => {
  let currentDate = moment(momentDate).set({ hour: 9, minute: 0 }).unix()
  const endDate = moment(momentDate).set({ hour: 19, minute: 0 }).unix()

  const dateArray = []

  while (currentDate < endDate) {
    const lastDate = moment.unix(currentDate).add(30, 'minutes').unix()

    const randomNumber = Math.floor(Math.random() * 100)

    const [currentHours] = dateParams(currentDate)
    const [lastHours] = dateParams(lastDate)

    const conditionOne = currentHours >= 9 && currentHours <= 18
    const conditionTwo = lastHours >= 9 && lastHours <= 18

    if (randomNumber < 30 && conditionTwo && conditionOne) {
      dateArray.push({
        firstDate: formatDate(currentDate),
        lastDate: formatDate(lastDate),
        firstDateUnix: currentDate,
        lastDateUnix: lastDate
      })
    }

    currentDate = lastDate
  }

  return dateArray
}

export default generateDateArray

// Пример использования:
// const startDate = new Date() // Установите начальную дату по вашему желанию
// const endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000) // Установите конечную дату по вашему желанию
// const intervalInMinutes = 45

// const resultArray = generateDateArray(startDate, endDate, intervalInMinutes)
// console.log(resultArray)
