import { Line } from 'react-chartjs-2'
import { Radio } from 'antd'
import styled from 'styled-components'
const LineStyled = styled(Line)`
  width: 100% !important;
  height: 100% !important;
`

const GroupStyled = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    background-color: var(--ql-color-secondary);
    border-color: var(--ql-color-secondary-lighten1);
    color: #000;
  }
  .ant-radio-button-checked {
    background-color: var(--ql-color-primary-lighten1);
    color: #fff !important;
    border-color: transparent !important;
    outline-color: transparent !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    outline-color: transparent !important;
    border-color: var(--ql-color-primary-lighten1) !important;
  }
`

export { LineStyled, GroupStyled }
