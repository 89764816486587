import { useCallback, useEffect, useMemo, useState } from 'react'

const object = {}

const useGetCryptoPriceDuration = (symbol, pageName) => {
  const [price, setPrice] = useState('')
  const [period, setPeriod] = useState('week')

  const transformDateToUnix = (data) => Math.floor(data.getTime() / 1000)

  const getMonth = useCallback(() => {
    const currDate = new Date()
    currDate.setMonth(currDate.getMonth() - 1)
    return transformDateToUnix(currDate)
  }, [])

  const getWeek = useCallback(() => {
    const currDate = new Date()
    currDate.setDate(currDate.getDate() - 7)
    return transformDateToUnix(currDate)
  }, [])

  const getYear = useCallback(() => {
    const currDate = new Date()
    currDate.setFullYear(currDate.getFullYear() - 1)
    return transformDateToUnix(currDate)
  }, [])

  const getDay = useCallback(() => {
    const currDate = new Date()
    currDate.setUTCHours(0, 0, 0, 0)
    return transformDateToUnix(currDate)
  }, [])

  const ranges = useMemo(
    () => ({
      month: getMonth,
      week: getWeek,
      year: getYear,
      day: getDay
    }),
    []
  )

  const getCryptoPriceDuration = useCallback(async (period) => {
    try {
      if (!period) return []
      const computedKey = `${pageName}${period}`.toLowerCase()

      if (object?.[computedKey]) {
        return setPrice(object?.[computedKey])
      }

      const currentTime = Math.floor(Date.now() / 1000)

      const computedPeriod = ranges?.[period]?.() ?? ranges.year()

      if (symbol === 'bnb') symbol = 'binancecoin'
      const priceData = await fetch(
        `https://api.coingecko.com/api/v3/coins/${symbol}/market_chart/range?vs_currency=USD&from=${computedPeriod}&to=${currentTime}`
      )

      const final = await priceData.json()
      if (final) {
        let prices = final?.prices

        let val = 0

        if (prices?.length && period === 'month') {
          prices = prices?.filter(([unix, _], i) => {
            // const data = new Date(unix)

            // data.setHours(23)
            // data.setMinutes(59)
            // data.setSeconds(59)

            // const currentDay = data.getDate()
            // const formattedDataTimestamp = data.valueOf()

            // const nextItem = prices?.[i + 1]
            // const nextTimestamp = nextItem?.[0]
            // const nextDay = new Date(nextTimestamp).getDate()
            // return unix <= formattedDataTimestamp && nextDay !== currentDay

            if (val === 4) val = 0
            val = val + 1

            return val === 4
          })
        }

        if (!object?.[computedKey]) {
          object[computedKey] = prices
        }

        setPrice(prices)
      }
      return price
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    isMounted && getCryptoPriceDuration(period)
    return () => (isMounted = false)
  }, [period])

  return [price, period, setPeriod]
}

export default useGetCryptoPriceDuration
