const LitecoinIcon = () => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
      <g id="surface1">
        <path
          style={{ stroke: 'none', fillRule: 'nonzero', fill: '#32C99C' }}
          d="M 8.878906 3.664062 C 8.683594 4.324219 8.167969 6.054688 7.730469 7.507812 L 6.941406 10.152344 L 6.820312 10.191406 C 6.753906 10.210938 6.339844 10.34375 5.898438 10.488281 L 5.097656 10.742188 L 4.90625 11.429688 C 4.800781 11.804688 4.726562 12.121094 4.738281 12.132812 C 4.75 12.144531 5.144531 12.03125 5.613281 11.882812 C 6.078125 11.730469 6.472656 11.613281 6.480469 11.625 C 6.496094 11.636719 5.015625 16.648438 4.933594 16.863281 C 4.917969 16.902344 5.886719 16.914062 9.703125 16.914062 L 14.488281 16.914062 L 14.605469 16.53125 C 14.671875 16.324219 14.855469 15.734375 15.011719 15.226562 L 15.300781 14.296875 L 12.058594 14.296875 C 8.992188 14.296875 8.820312 14.292969 8.835938 14.226562 C 8.847656 14.191406 9.097656 13.332031 9.394531 12.324219 L 9.929688 10.484375 L 10.773438 10.214844 L 11.617188 9.941406 L 11.820312 9.238281 C 11.929688 8.851562 12.015625 8.53125 12.011719 8.523438 C 12.007812 8.519531 11.648438 8.628906 11.210938 8.769531 C 10.777344 8.910156 10.40625 9.023438 10.390625 9.015625 C 10.378906 9.007812 10.792969 7.542969 11.3125 5.761719 C 11.835938 3.976562 12.265625 2.507812 12.265625 2.488281 C 12.265625 2.472656 11.585938 2.460938 10.753906 2.460938 L 9.238281 2.460938 Z M 8.878906 3.664062 "
        />
      </g>
    </svg>
  )
}

export default LitecoinIcon
