import { BoilerplateLayout, LoadingScreen } from 'components'
import { Redirect, Route, Switch } from 'react-router-dom'

import { CITIES_EXCHANGES } from '__constants__'
import { ROUTES } from './routes'
import { generateDateArray } from 'helpers'
import moment from 'moment'
import { useCurrencyContext } from 'contexts/CurrencyContext'
import { useEffect } from 'react'

const App = () => {
  const { isLoading = true } = useCurrencyContext()

  useEffect(() => {
    const currenciesJSON = localStorage.getItem('currencies')
    const currenciesData = JSON?.parse(currenciesJSON ?? 'null')

    if (!currenciesData) {
      localStorage.setItem(
        'currencies',
        JSON.stringify({
          receiving: 'USD',
          dispatch: 'USDT'
        })
      )
    }
  }, [])

  const generateDataForExchanges = ({
    currentDate,
    nextDate,
    formattedCurrentDate,
    formattedNextDate,
    exchange
  }) => {
    const availableDeliveryDatasJSON = localStorage.getItem(exchange)
    const availableDeliveryDatas = availableDeliveryDatasJSON
      ? JSON.parse(availableDeliveryDatasJSON)
      : {}

    const copyData = Object.fromEntries(
      Object.entries(availableDeliveryDatas)?.filter(
        ([data]) => +data >= +formattedCurrentDate
      )
    )

    if (!copyData?.[formattedCurrentDate]) {
      const currentDateArray = generateDateArray(currentDate)
      copyData[formattedCurrentDate] = currentDateArray
    }

    if (!copyData?.[formattedNextDate]) {
      const nextDateArray = generateDateArray(nextDate)
      copyData[formattedNextDate] = nextDateArray
    }

    localStorage.setItem(exchange, JSON.stringify(copyData))
  }

  // Generate delivery time
  useEffect(() => {
    const currentDate = moment()
    const nextDate = moment().add(1, 'days')

    const formatDate = (momentDate) => moment(momentDate).format('YYYYMMDD')

    const formattedCurrentDate = formatDate(currentDate)
    const formattedNextDate = formatDate(nextDate)

    Object.entries(CITIES_EXCHANGES)?.forEach(([city, exchanges]) => {
      exchanges?.forEach(({ value }) =>
        value
          ? generateDataForExchanges({
              currentDate,
              nextDate,
              formattedCurrentDate,
              formattedNextDate,
              exchange: `${city}${value}`
            })
          : null
      )
    })
  }, [])

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <BoilerplateLayout>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/dashboard" />
        </Route>
        {ROUTES.map((routeProps, i) => (
          <Route key={`${routeProps?.key}-${i}`} {...routeProps} />
        ))}
      </Switch>
    </BoilerplateLayout>
  )
}

export default App
