const RippleIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 22 20"
    version="1.1"
    style={{ transform: 'translate(6%, 0)' }}>
    <g id="surface1">
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#32C99C',
          fillOpacity: '1'
        }}
        d="M 4.765625 5.949219 C 6.21875 7.398438 7.394531 8.539062 7.539062 8.640625 C 8.285156 9.167969 9.085938 9.421875 10 9.421875 C 10.914062 9.421875 11.710938 9.167969 12.457031 8.644531 C 12.609375 8.539062 13.746094 7.433594 15.234375 5.953125 L 17.753906 3.4375 L 15.375 3.4375 L 13.320312 5.480469 C 11.792969 7.007812 11.214844 7.558594 11.054688 7.644531 C 10.679688 7.851562 10.453125 7.910156 10 7.90625 C 9.539062 7.90625 9.238281 7.824219 8.886719 7.609375 C 8.773438 7.539062 7.824219 6.621094 6.660156 5.460938 L 4.628906 3.4375 L 2.246094 3.4375 Z M 4.765625 5.949219 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#32C99C',
          fillOpacity: '1'
        }}
        d="M 9.585938 10.585938 C 8.667969 10.679688 7.878906 11.035156 7.152344 11.683594 C 6.945312 11.871094 5.757812 13.042969 4.511719 14.292969 L 2.246094 16.5625 L 4.628906 16.5625 L 6.660156 14.539062 C 7.824219 13.378906 8.773438 12.460938 8.886719 12.390625 C 9.238281 12.175781 9.539062 12.09375 10 12.09375 C 10.453125 12.089844 10.679688 12.148438 11.054688 12.355469 C 11.214844 12.441406 11.789062 12.988281 13.320312 14.515625 L 15.371094 16.5625 L 17.753906 16.5625 L 15.234375 14.046875 C 13.746094 12.566406 12.609375 11.460938 12.457031 11.355469 C 11.617188 10.765625 10.582031 10.488281 9.585938 10.585938 Z M 9.585938 10.585938 "
      />
    </g>
  </svg>
)

export default RippleIcon
