const CRYPTO_WALLET_LENGTHS = {
  ETH: {
    min: 40,
    max: 44,
    placeholder: 'QGU2G8QUUFNK2bJNFfuk6636SFoYEQVPdMxpCX7G'
  },
  BTC: {
    min: 32,
    max: 62,
    placeholder: '7VMEsmhy0WlcpB653NaXxLVpHjSabzO6XuHR7xMKEH'
  },
  USDT: {
    min: 30,
    max: 40,
    placeholder: 'Elz1KeqUprY7jWGahrGea7LUarlarPkvFCL'
  }
}

export { CRYPTO_WALLET_LENGTHS }
export default CRYPTO_WALLET_LENGTHS
