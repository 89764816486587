import { Button } from '@qonsoll/react-design'
import styled from 'styled-components'

const ButtonSecondary = styled(Button)`
  background-color: var(--ql-color-secondary-lighten1);
  color: #000;
  &:hover {
    background-color: var(--ql-color-secondary-lighten1);
    color: rgba(0, 0, 0, 0.5);
  }
  &:focus {
    background-color: var(--ql-color-secondary-lighten1);
    color: inherit;
  }
`

export default ButtonSecondary
