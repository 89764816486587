const PERCENT_LIST = {
  // RUB*val*
  RUBUSDT: 1.6, // done
  RUBBTC: 8, // done
  RUBETH: 1.5, // done
  RUBLTC: 3, // done
  RUBBNB: 2.5, // done
  RUBXRP: 1.3, // done

  // *val*RUB
  USDTRUB: 0.25, // done
  BTCRUB: -1, // done
  ETHRUB: -1.5, // done
  LTCRUB: -1.5, // done
  BNBRUB: -1.5, // done
  XRPRUB: -1.8, // done

  // SKIP
  // USD*val*
  USDBTC: 2.5, // done
  USDETH: 2.5, // done
  USDLTC: 2.5, // done
  USDBNB: 2.2, // done
  USDXRP: 2.4, // done

  // *val*USD
  BTCUSD: -0.5, // done
  ETHUSD: -0.5, // done
  LTCUSD: -0.5, // done
  BNBUSD: -1.2, // done
  XRPUSD: 0.6, // done
  //* ===== *//

  // BTC*val*
  BTCUSDT: -1, // done,
  BTCETH: 1.2, // done
  BTCLTC: 1.4, //done
  BTCBNB: 1.4, //done
  BTCXRP: 1, // done
  // *val*BTC*
  USDTBTC: 1.6, // done
  ETHBTC: -0.5, // done
  LTCBTC: -0.8, //done
  BNBBTC: -1, //done
  XRPBTC: -1.7, // done

  // ETH*val
  ETHUSDT: 0, //done
  ETHLTC: 0.5, //done
  ETHBNB: 0.5, //done
  ETHXRP: -0.5, // done
  // *val*ETH
  USDTETH: 1, //done
  LTCETH: -1.1, //done
  BNBETH: -1, //done
  XRPETH: 1.5, //done

  // BNB*val*
  BNBUSDT: -1.5, //done
  BNBLTC: 1, //done
  BNBXRP: 0.8, //done
  // *val*BNB*
  USDTBNB: 1, //done
  LTCBNB: -1, //done
  XRPBNB: -0.5, //done

  // XRP*val*
  XRPUSDT: -0.5, //done
  // *val*XRP
  USDTXRP: 0.8, //done

  //LTC*val*
  LTCUSDT: -0.5, //done
  // val*LTC*
  USDTLTC: 1 //done
}

export default PERCENT_LIST
