import { Col, Row, Text } from '@qonsoll/react-design'

import { Divider } from 'antd'
import { Icon } from '@qonsoll/icons'
import Marquee from 'react-fast-marquee'
import { useCurrencyContext } from 'contexts/CurrencyContext'

const Header = () => {
  const { btc, eth, ltc, bnb, xrp, usdt } = useCurrencyContext()

  const transformPrice = (price) =>
    price ? Number((+price)?.toFixed?.(9)) : null

  const cryptoHeader = [
    {
      title: 'BTC:',
      price: transformPrice(btc?.price),
      priceChange: btc?.priceChange
    },
    {
      title: 'ETH:',
      price: transformPrice(eth?.price),
      priceChange: eth?.priceChange
    },
    {
      title: 'USDT:',
      price: transformPrice(usdt?.price),
      priceChange: usdt?.priceChange
    },
    {
      title: 'LTC:',
      price: transformPrice(ltc?.price),
      priceChange: ltc?.priceChange
    },
    {
      title: 'BNB:',
      price: transformPrice(bnb?.price),
      priceChange: bnb?.priceChange
    },
    {
      title: 'XRP:',
      price: transformPrice(xrp?.price),
      priceChange: xrp?.priceChange
    }
  ]

  return (
    <>
      <Row noGutters className="my-12">
        <Col cw={12}>
          <div className="overflow-hidden">
            <Marquee>
              {cryptoHeader?.map((item) => (
                <div className="flex align-center mr-12">
                  <Text fontWeight="bold" fontSize={12} mr={1} color="#606b81">
                    {item?.title}
                  </Text>
                  <Text mr={1} fontWeight="bold" fontSize={12} color="#4c6ce0">
                    {item?.price}
                  </Text>
                  {item?.priceChange >= 0 ? (
                    <Icon fill="#299d7a" size={14} name="ArrowLongUpFilled" />
                  ) : (
                    <Icon fill="#ba4d4d" size={14} name="ArrowLongDownFilled" />
                  )}
                </div>
              ))}
            </Marquee>
          </div>
        </Col>
      </Row>
      <Divider />
    </>
  )
}

export default Header
