import { Col, Row, Text, Title } from '@qonsoll/react-design'

import PATHS from 'pages/paths'
import xrpIcon from 'assets/xrp-logo-circle.png'

const cryptoPageData = {
  bitcoin: {
    icon: 'https://a.storyblok.com/f/120064/x/888ab7502e/icon_bitcoin.svg',
    pageTitle: 'Bitcoin (BTC)',
    mark: 'BTC',
    path: PATHS.BITCOIN,
    text: (
      <>
        <Row mb={40}>
          <Col span={12}>
            <Title color="#333333" level={3}>
              What factors influence the Bitcoin exchange rate?
            </Title>
          </Col>
        </Row>
        <Row mb={20}>
          <Col>
            <Text color="#535079">
              Bitcoin, like most other cryptocurrencies, is characterized by
              instability, strong dynamics, and volatility. The coin's quotation
              in the crypto market is influenced by many factors, including:
            </Text>
          </Col>
        </Row>
        <Row mb={60}>
          <Col>
            <Text color="#535079">
              - Supply and demand balance
              <br /> - Mining costs
              <br />- Limited emission
              <br /> - Availability of coins on exchanges
              <br /> - Competition from altcoins
              <br /> - Government regulation, acceptance of Bitcoin as an
              official means of payment
              <br /> - Widespread acceptance of Bitcoin as a legitimate payment
              method
              <br /> - Information environment: news and influencer publications
              <br />- Global political situation and economic conditions
              worldwide.
            </Text>
          </Col>
        </Row>
        <Row mb={16}>
          <Col>
            <Title color="#535079" level={4}>
              What are the exchange rate forecasts?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text mb={20} color="#535079">
              Forecasting the Bitcoin exchange rate is challenging, but certain
              trends can still be tracked. Despite the crash in Aug, it still
              has potential for further growth and positive movement. In the
              long term, the key player in the crypto industry has every chance
              to recover lost ground and reach a new level of development.
            </Text>
            <Text mb={20} color="#535079">
              According to analysts' forecasts, Bitcoin could still reach the
              $100,000 mark. The worst-case scenario is a price drop to $41,000.
              Even if Bitcoin's price continues to fall, it is likely that the
              coin’s value will start to rise again afterward. This is not the
              first or last time this happens. Such corrections are normal for
              highly volatile assets. The Bitcoin price chart over the past
              three years reflects this.
            </Text>
          </Col>
        </Row>
        <Row mb={15}>
          <Col>
            <Title color="#535079" level={4}>
              Brief history of Bitcoin's price in numbers?
            </Title>
          </Col>
        </Row>
        <Row mb={60}>
          <Col>
            <Text mb={20} color="#535079">
              In January 2019, the Bitcoin price was below $4,000, but by July
              of the same year, one coin was worth $12,000. The year 2020
              started with the cryptocurrency priced at $7,000, and until
              December, the Bitcoin price showed relative stability. At the end
              of 2020, the digital asset's value began to rise rapidly, and 2021
              started with a price of $30,000..
            </Text>
            <Text mb={20} color="#535079">
              In April of the current year, the cryptocurrency reached its
              historical peak of $63,000, but by May, the coin had almost halved
              in value. The Bitcoin to Ruble exchange rate at the beginning of
              June 2021 was below one million rubles. The negative news backdrop
              was a contributing factor: Tesla announced it would stop using
              cryptocurrency as a payment method, Elon Musk repeatedly
              criticized Bitcoin, and Chinese authorities applied regulatory
              pressure on the crypto industry, calling for strict regulation of
              mining and trading.
            </Text>
            <Text color="#535079">
              As of early June 2024, Bitcoin is trading at $60,000. To find the
              current Bitcoin to Ruble exchange rate, use an online Bitcoin rate
              calculator.
            </Text>
          </Col>
        </Row>
      </>
    )
  },
  ethereum: {
    icon: 'https://a.storyblok.com/f/120064/x/ecc55642b3/icon_eth_2.svg',
    pageTitle: 'Ethereum (ETH)',
    mark: 'ETH',
    path: PATHS.ETHEREUM,
    text: (
      <>
        <Row mb={40}>
          <Col>
            <Title color="#333333" level={3}>
              ETHEREUM
            </Title>
          </Col>
        </Row>
        <Row mb={60}>
          <Col>
            <Text mb={20} color="#535079">
              Ethereum is the largest altcoin in the cryptocurrency market, the
              second most popular and valuable digital asset in the world, and
              one of the leading cryptocurrencies today. It is a decentralized
              computing platform built on blockchain technology; it functions as
              a cryptocurrency (with Ethereum’s exchange rate being the second
              largest after Bitcoin) and also as a programming language.
              Ethereum can do much more than just facilitate virtual money
              exchange: users can develop their own programs and interact with
              applications created by others. The system is flexible, and
              Ethereum allows for the creation of complex projects based on it.
            </Text>
            <Text mb={20} color="#535079">
              Ethereum appeared on the crypto market in 2015, and the concept
              was developed by Vitalik Buterin, the creator of Bitcoin Magazine.
              ETH was designed not only as a payment system but as a foundation
              for implementing blockchain technology. The main idea of the
              project is to allow developers to generate and execute code in a
              distributed network that cannot be externally influenced. This
              means that users have much more freedom in creating decentralized
              applications.
            </Text>
            <Text color="#535079">
              Ethereum is a cryptocurrency that functions as a transferable
              payment medium, but unlike other virtual assets, its role is not
              limited to just that. Ethereum's smart contracts and computer
              algorithms can be applied in various fields, opening new
              opportunities for creating and supporting financial platforms.
            </Text>
            <Text color="#535079">
              Ethereum belongs to a different generation and application of
              blockchain technology compared to Bitcoin. It was the first on the
              wave of second-generation blockchains and remains the most popular
              and widespread in this segment today.
            </Text>
            <Text color="#535079">
              Every Ethereum user must have a certain amount of the
              corresponding cryptocurrency. As the number of applications in the
              network increases, so does the demand for its asset, and more
              people are interested in buying Ethereum. Increased attention from
              global companies and significant expansion of Ethereum's usage
              make the cryptocurrency increasingly popular and in demand.
            </Text>
          </Col>
        </Row>
      </>
    )
  },
  tether: {
    icon: 'https://a.storyblok.com/f/120064/x/b452c36a06/icon_usdt_2.svg',
    pageTitle: 'Tether (USDT)',
    mark: 'USDT',
    path: PATHS.TETHER,
    text: (
      <>
        <Row mb={40}>
          <Col>
            <Title level={3}>About Tether in a nutshell</Title>
          </Col>
        </Row>
        <Row style={{ widh: '100%' }} mb={30}>
          <Col>
            <Text mb={20} color="#535079">
              Tether is a cryptographic equivalent of the US dollar, the first
              and most popular stablecoin in the world. Its essence and
              uniqueness lie in its fiat backing by US dollars (USDT — United
              States Dollar Tether). Its value is always stable and consistently
              aims for a 1:1 ratio with the US currency.
            </Text>
            <Text mb={20} color="#535079">
              Tether appeared on the cryptocurrency market in 2015, and as of
              early June 2021, USDT is the third-largest cryptocurrency in the
              world by trading volume, following only Bitcoin and Ethereum. The
              total market capitalization of Tether exceeds $62 billion.
            </Text>
            <Text color="#535079">
              The main idea of the project is the stability of the coin,
              reflecting the dollar, and enabling free conversion of US currency
              into digital money. Thus, Tether bridges the gap between virtual
              assets and fiat money.
            </Text>
            <Text color="#535079">
              The creators of the cryptocurrency, developers from Tether
              Limited, assure that the coin's value is 20% backed by US dollar
              reserves held in bank accounts of the company based in Hong Kong.
            </Text>
            <Text color="#535079">
              The feature of USDT is that the cryptocurrency addresses key
              issues of digital assets — lack of fiat backing and exchange rate
              volatility.
            </Text>
          </Col>
        </Row>
        <Row mb={30}>
          <Col>
            <Title color="#535079" level={3}>
              What affects the USDT exchange rate?
            </Title>
          </Col>
        </Row>
        <Row mb={60}>
          <Col>
            <Text mb={20} color="#535079">
              The price of USDT does not depend on how frequently the coin is
              bought or sold. The coin's quotations are also not significantly
              affected by the overall situation in the cryptocurrency market.
              Analyzing the USDT price chart shows that the trading range does
              not exceed 1.5-2%.
            </Text>
            <Text mb={20} color="#535079">
              The Tether exchange rate to the Ruble depends on the exchange rate
              of the fiat currency to which the coin is pegged, the overall
              situation in financial markets, and the global economic
              environment. Thus, the strengthening of the dollar always leads to
              a strengthening of USDT positions. Additionally, the USDT rate is
              largely influenced by Tether Limited, which is supposed to
              maintain the amount of Tether in circulation, although a decline
              in the rate is still possible. To find the current USDT to Ruble
              exchange rate, use an online USDT rate calculator.
            </Text>
          </Col>
        </Row>
      </>
    )
  },
  litecoin: {
    icon: 'https://a.storyblok.com/f/120064/x/54f8710182/icon_ltc_2.svg',
    pageTitle: 'Litecoin (LTC)',
    mark: 'LTC',
    path: PATHS.LITECOIN,
    text: (
      <>
        <Row mb={40}>
          <Col>
            <Title level={3}>What affects the Litecoin exchange rate?</Title>
          </Col>
        </Row>
        <Row mb={30}>
          <Col>
            <Text mb={20} color="#535079">
              The LTC exchange rate relative to the Ruble and the dollar is
              influenced by the same factors that affect the quotations of most
              other cryptocurrencies. Thus, the price of Litecoin on exchanges
              is determined by:
            </Text>
            <Text mb={20} color="#535079">
              - Supply and demand balance
              <br /> - Market capitalization of the cryptocurrency
              <br /> - Current state of the crypto industry and investor
              sentiment
              <br /> - Total number of coins in circulation
              <br /> - Bitcoin's price and its movement dynamics (Bitcoin’s rise
              positively impacts the value of all altcoins)
              <br /> - News surrounding the coin and prospects in the crypto
              market overall <br />
              - Activities of developers, community information, and updates
              <br /> - Trends towards cryptocurrency legalization globally,
              regulation by governments and financial institutions
              <br /> - Political and economic global context
              <br /> - Technical analysis on the chart.
            </Text>
            <Text color="#535079">
              Litecoin, like most other digital coins, has almost no intrinsic
              value. The cryptocurrency's price is entirely dependent on
              speculative factors. This means that to make a forecast regarding
              the asset's value, technical analysis is necessary. This will not
              only help understand the principles of Litecoin's price formation
              but also determine the direction of its price movement in the
              future
            </Text>
          </Col>
        </Row>
        <Row mb={30}>
          <Col>
            <Title color="#535079" level={3}>
              LTC Price Forecast for 2024
            </Title>
          </Col>
        </Row>
        <Row mb={60}>
          <Col>
            <Text mb={20} color="#535079">
              The forecasts for Litecoin's price are based on the coin's high
              popularity. According to experts, the LTC exchange rate relative
              to the dollar next year looks much more optimistic compared to the
              current year. It is predicted that the cryptocurrency's value will
              begin to rise starting in January, although it is currently
              unclear what exactly will drive this positive trend.
            </Text>
            <Text mb={20} color="#535079">
              Although periods of decline for Litecoin cannot be ruled out in
              2024, overall, positive changes in price are expected. The year is
              anticipated to start at the current level for the coin, with a
              price increase expected within a few months, surpassing $300 and
              potentially reaching a new historical high beyond May's peak.
              Additionally, according to forecasted prices and technical
              analysis of Litecoin, a record-breaking crossing of the $400
              threshold is anticipated in 2023.
            </Text>
            <Text mb={20} color="#535079">
              Experts predict that throughout the upcoming year, the
              cryptocurrency will exhibit exclusively positive dynamics with
              slow but steady growth. However, Litecoin’s high volatility is
              evident — the range between the minimum and maximum forecasted
              values is over $50.
            </Text>
            <Text mb={20} color="#535079">
              Nevertheless, analysts agree that Litecoin will continue to grow
              in the coming year, making investments in this cryptocurrency
              appear quite profitable. However, it is important to understand
              that forecasts regarding the future price of the coin are only
              speculative. The high volatility of the cryptocurrency and the
              unpredictability of its price make long-term forecasts uncertain.
            </Text>
          </Col>
        </Row>
      </>
    )
  },
  ripple: {
    icon: xrpIcon,
    pageTitle: 'Ripple (XRP)',
    mark: 'XRP',
    path: PATHS.XRP,
    text: (
      <>
        <Row mb={40}>
          <Col>
            <Title level={3}>What affects the Ripple exchange rate?</Title>
          </Col>
        </Row>
        <Row mb={30}>
          <Col>
            <Text mb={20} color="#535079">
              The Ripple exchange rate on the crypto market is akin to a roller
              coaster ride — the instability of the coin's value exposes
              investors to dramatic fluctuations.
            </Text>
            <Text mb={20} color="#535079">
              Ripple is a cryptocurrency that is heavily influenced by external
              factors, particularly statements from influential figures and
              media events. One of the key factors affecting Ripple’s price is
              the commentary from major figures in the cryptocurrency and
              financial markets.
            </Text>
            <Text mb={20} color="#535079">
              Thus, the main influencing factors on Ripple’s price are opinion
              leaders and the "hype" effect. The potential growth and future
              popularity of the cryptocurrency largely depend on these.
            </Text>
            <Text mb={20} color="#535079">
              Additionally, the coin reflects investor expectations. This means
              that Ripple’s exchange rate relative to the Ruble, dollar, euro,
              and other fiat currencies depends on the growth of the crypto
              market and the general sentiment of its participants.
            </Text>
            <Text color="#535079">
              The value of Ripple, like other altcoins, is also affected by the
              price of the major player in the cryptocurrency market. Bitcoin’s
              price swings and optimistic forecasts always have a positive
              impact on Ripple’s dynamics.
            </Text>
          </Col>
        </Row>
        <Row mb={30}>
          <Col>
            <Title color="#535079" level={3}>
              Ripple Price Forecast for 2023
            </Title>
          </Col>
        </Row>
        <Row mb={60}>
          <Col>
            <Text mb={20} color="#535079">
              Ripple, according to some, is one of the most remarkable
              cryptocurrencies that has grown this year (XRP). The XRP price
              forecast for 2024 suggests a significant increase in the second
              half of the year, potentially reaching $0.7389.
            </Text>
            <Text mb={20} color="#535079">
              The market's reaction to news and SEC decisions also plays a
              crucial role in determining the cryptocurrency's price. Investors
              respond to news and developments, which can lead to sharp price
              fluctuations.
            </Text>
            <Text mb={20} color="#535079">
              However, forecasts for the asset's value should be approached with
              some skepticism. It is important to conduct your own analysis,
              consider expert opinions, track trends, and continuously monitor
              the Ripple price chart. Approach investments thoughtfully and
              responsibly, ignore sudden price spikes, and avoid letting
              emotions influence your decisions when investing in digital
              assets.
            </Text>
          </Col>
        </Row>
      </>
    )
  },
  bnb: {
    icon: 'https://seeklogo.com/images/B/binance-coin-bnb-logo-97F9D55608-seeklogo.com.png',
    pageTitle: 'Binance Coin (BNB)',
    mark: 'BNB',
    path: PATHS.BNB,
    text: (
      <>
        <Row mb={40}>
          <Col>
            <Title level={3}>What affects BNT rate</Title>
          </Col>
        </Row>
        <Row mb={30}>
          <Col>
            <Text mb={20} color="#535079">
              The price of BNB results from numerous internal and external
              factors related specifically to this cryptocurrency. Inside the
              Binance exchange, BNB plays an important role as it can be used to
              pay for exchange fees, participate in initiatives like Binance
              Launchpad, and receive trading fee discounts.
            </Text>
            <Text mb={20} color="#535079">
              The trading volume of BNB on the exchange significantly impacts
              its price. Additionally, Binance periodically conducts its own BNB
              burn programs, which reduces the total supply of the coin and can
              support price growth.
            </Text>
            <Text color="#535079">
              External factors also influence the Binance Coin exchange rate.
              News and regulatory decisions in various countries can cause
              significant price fluctuations for BNB. Regulatory measures or
              positive decisions can impact investor confidence and the use of
              the cryptocurrency.
            </Text>
          </Col>
        </Row>
        <Row mb={30}>
          <Col>
            <Title color="#535079" level={3}>
              BNB Price Forecast for 2024
            </Title>
          </Col>
        </Row>
        <Row mb={60}>
          <Col>
            <Text mb={20} color="#535079">
              Forecasting the price of cryptocurrencies, including Binance Coin
              (BNB), is a complex task and is always associated with a high
              degree of uncertainty. In 2024, the price of BNB may be influenced
              by a range of factors. However, it is important to note that BNB
              is closely tied to the activities of the Binance exchange, and
              internal events on this platform, such as the introduction of new
              products and services, may have a significant impact on the price
              of BNB.
            </Text>
            <Text mb={20} color="#535079">
              Additionally, regulatory changes and positive news about
              cryptocurrency regulation in various countries may influence
              overall investor sentiment and, consequently, the price of BNB.
              However, forecasting the price of BNB for 2023 remains a purely
              speculative task, and it is recommended to closely follow news,
              consider supply and demand factors, and assess risks associated
              with cryptocurrency investments.
            </Text>

            <Text mb={20} color="#535079">
              Therefore, invest only what you are prepared to lose.{' '}
            </Text>
          </Col>
        </Row>
      </>
    )
  }
}

export default cryptoPageData
