import { Input } from '@qonsoll/react-design'
import styled from 'styled-components'

const InputStyled = styled(Input)`
  background-color: transparent;
  padding: 14px 16px;
  line-height: 1;
`

export { InputStyled }
