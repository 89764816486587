import React from 'react'

const BitcoinIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1025 8.79836C16.3688 7.07982 15.0133 6.15594 13.1598 5.53962L13.761 3.21159L12.2931 2.85845L11.7077 5.12512C11.3219 5.03225 10.9255 4.94471 10.5316 4.85795L11.1211 2.57628L9.65402 2.22314L9.05235 4.5504C8.73292 4.48014 8.41932 4.41074 8.11496 4.33763L8.1166 4.33038L6.09213 3.84242L5.70159 5.35594C5.70159 5.35594 6.7908 5.59694 6.76776 5.61187C7.36232 5.75516 7.46977 6.13497 7.45178 6.43605L6.76691 9.08814C6.80794 9.09824 6.86099 9.11282 6.91958 9.13543C6.87066 9.1237 6.81839 9.11075 6.76442 9.09824L5.80449 12.8135C5.73167 12.9879 5.54735 13.2494 5.13171 13.1501C5.14635 13.1707 4.06468 12.893 4.06468 12.893L3.33594 14.5151L5.24627 14.9749C5.60168 15.0608 5.94998 15.1509 6.29281 15.2356L5.68531 17.5903L7.15162 17.9434L7.75329 15.6138C8.15379 15.7187 8.54262 15.8156 8.92314 15.9068L8.32353 18.2256L9.79155 18.5787L10.399 16.2285C12.9023 16.6858 14.7846 16.5013 15.5769 14.3157C16.2154 12.556 15.5451 11.5409 14.2281 10.879C15.1872 10.6655 15.9097 10.0565 16.1025 8.79836ZM12.7484 13.3386C12.2947 15.0984 9.22543 14.1471 8.2303 13.9085L9.03642 10.789C10.0315 11.0288 13.2225 11.5034 12.7484 13.3386ZM13.2025 8.77297C12.7885 10.3737 10.2339 9.56039 9.4052 9.36099L10.1361 6.5317C10.9647 6.73109 13.6335 7.10322 13.2025 8.77297Z"
        fill="#32C99C"
      />
    </svg>
  )
}

export default BitcoinIcon
