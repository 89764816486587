import { Card, Col, Row, Text, Title } from '@qonsoll/react-design'

import { BoxStyled } from './Exchange.styled'
import { Icon } from '@qonsoll/icons'
import RightSide from './RightSide'
import { Tooltip } from 'antd'
import { useCurrentScreen } from 'helpers'
import { useMemo } from 'react'

const primaryColor = 'rgb(110, 222, 189)'
const whiteLighten1 = 'rgba(255, 255, 255, 0.6)'

const subDescription = [
  {
    title: 'Transactions',
    text: 'From $10'
  },
  {
    title: 'Monthly limit',
    text: '100 000$'
  },
  {
    title: 'Fee',
    text: 'No fee',
    icon: (
      <Tooltip
        placement="top"
        title={'You receive exactly the amount displayed in the exchange form'}>
        <Icon
          fill={primaryColor}
          size="14px"
          mr={2}
          name="InfoCircleOutlined"
        />
      </Tooltip>
    )
  }
]

const Exchange = () => {
  const { isSmallScreen, isLargeScreen, lg, xs } = useCurrentScreen()

  const computedWrapperPadding = useMemo(
    () =>
      isSmallScreen ? '25px 0px' : isLargeScreen ? '45px 20px' : '105px 80px',
    [isSmallScreen, isLargeScreen]
  )

  const subDescriptionItem = subDescription?.map((item, i) => (
    <Col cw={isSmallScreen ? 'auto' : 4} key={`${item?.title}${i}`}>
      <div>
        <Text color={whiteLighten1} variant={'body2'}>
          {item?.title}
        </Text>
      </div>
      <div className="flex align-center">
        {item?.icon}
        <Text color={primaryColor} variant={'body1'}>
          {item?.text}
        </Text>
      </div>
    </Col>
  ))

  return (
    <BoxStyled p={computedWrapperPadding}>
      <Row className="row-gap-12">
        <Col cw={isSmallScreen ? 12 : null}>
          <Row height="100%" v="center" h="between">
            <Col cw={12} mb="4">
              <Title color="#fff" level={isSmallScreen ? 3 : 1}>
                Currency exchange at wholesale prices
              </Title>
            </Col>

            <Col cw={12} mb={'3.25rem'}>
              <Text color="#fff">
                Fast, automatic, and no hidden fees. The simplest way to sell or
                buy cryptocurrency online. Minimal personal identification form.
                Support for any bank cards.
              </Text>
            </Col>

            <Col cw={12}>
              <Row className="row-gap-8" h="between">
                {subDescriptionItem}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col cw={isSmallScreen || !lg ? 12 : 5}>
          <Card
            style={{
              background: 'var(--ql-color-primary)'
            }}
            bodyStyle={{
              padding: xs ? '14px' : '24px'
            }}>
            <RightSide />
          </Card>
        </Col>
      </Row>
    </BoxStyled>
  )
}

export default Exchange
