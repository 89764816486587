import { Card, Col, Container, Row, Text, Title } from '@qonsoll/react-design'
import {
  Footer,
  FrequentlyAskedQuestions,
  Header,
  NavigationBar
} from 'components'

import Advantages from './components/Advantages'
import OurAdvantages from './components/OurAdvanteges'
import RightSide from 'components/Exchange/RightSide'
import Security from './components/Security'
import { useCurrentScreen } from 'helpers'
import { useMemo } from 'react'

const faq = [
  {
    header: 'How long does it take to exchange crypto?',
    description:
      'We have simplified the entire process for our clients: 4 steps — and the currency operation is completed. It will take you from a few minutes to an hour to exchange Bitcoin or Ethereum on our service. Note that in rare cases, during network congestion, blockchain transactions can take several hours.'
  },
  {
    header: 'Do I need to be prepared for additional expenses?',
    description:
      'No. All expenses are already included in the service fee. The amount on the screen is the actual amount you will pay. We operate without hidden fees and additional charges. No tricks or unexpected charges. Everything is honest and transparent.'
  },
  {
    header: 'Is it safe to provide you with my card details?',
    description: `We do not store any personal information of our clients, especially bank card details. All financial transactions are carried out on the side of the payment gateway, which complies with the requirements of the international PCI DSS standard for payment card industry data security.`
  },
  {
    header: 'Is it legal?',
    description: `Absolutely. Our activities are conducted exclusively within the framework of the current legislation on the legalization of the cryptocurrency industry, in accordance with the Law of the "On Virtual Assets". OptChange is a reliable investment without risks. And only that.`
  }
]

const AboutUs = () => {
  const { isSmallScreen, xs } = useCurrentScreen()
  const rightSideCol = useMemo(() => 'auto', [isSmallScreen])

  const cryptoCoupleCol = useMemo(
    () => (isSmallScreen ? 12 : null),
    [isSmallScreen]
  )
  return (
    <>
      <Container style={{ width: isSmallScreen ? '100%' : '94%' }}>
        <Row>
          <Col cw={12}>
            <Header />
          </Col>
        </Row>
        <Row my={4}>
          <Col>
            <NavigationBar />
          </Col>
        </Row>

        <Container style={{ width: isSmallScreen ? '95%' : '90%' }}>
          <Row mb={6} className="row-gap-12" h="center" v="center">
            <Col cw={cryptoCoupleCol}>
              <Title className="mb-20">
                Cryptocurrency exchange without hidden fees
              </Title>
              <Text>
                Buy cryptocurrency profitably, conveniently, and securely
                through our online service. Opt Change offers a cryptocurrency
                exchange with no hidden fees, favorable conditions, simplicity,
                and a transparent operation algorithm. Experience all the
                advantages of working with an advanced exchange service. Opt
                Change specializes in exchanging rubles for Bitcoin, Ethereum,
                Tether, Litecoin, Binance Coin, and Ripple, allowing us to offer
                some of the best rates on the world market.
              </Text>
            </Col>
            <Col cw={rightSideCol}>
              <Card
                className="flex justify-center align-center"
                style={{
                  margin: '20px 0px',
                  background: 'var(--ql-color-primary)',
                  minWidth: '400px'
                }}
                bodyStyle={{
                  padding: xs ? '14px' : '24px'
                }}>
                <RightSide />
              </Card>
            </Col>
          </Row>
          <Row mb={6}>
            <Col>
              <Advantages />
            </Col>
          </Row>
          <Row mb={5}>
            <Col>
              <OurAdvantages />
            </Col>
          </Row>

          <Row mb={6}>
            <Col>
              <Title fontSize={32} className="mb-20">
                Security
              </Title>
              <Text>
                Your privacy and security are our top priority.
                <br />
                In our operations, we rely on global best practices in data
                protection, asset safety, and transaction security. <br />
                средств и транзакций. We make every effort to safeguard your
                assets, secure account access, prevent fraud, and ensure the
                complete safety of any information provided by our clients.
              </Text>
            </Col>
          </Row>

          <Row>
            <Col cw={12}>
              <Security />
            </Col>
          </Row>

          <Row mb={4}>
            <Col>
              <FrequentlyAskedQuestions faq={faq} />
            </Col>
          </Row>
          <Row mb={5}>
            <Col cw={12}>
              <Footer />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default AboutUs
