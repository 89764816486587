import { Box, Card, Col, Img, Row, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'

const CryptoCouple = (props) => {
  const { item } = props

  // const colorComputed = item?.isMinus ? 'red' : null
  return (
    <Card style={{ height: '100%' }}>
      <Row mb={12} v="center">
        <Col mr={2} p={0} cw="auto">
          <Box display="flex">
            <Img height="32px" width="32px" src={item?.firstIcon} />

            <Img ml={-12} height="32px" src={item?.secondIcon} />
          </Box>
        </Col>
        <Col className="mr-48" p={0} cw="auto">
          <Text>{item?.coupleName}</Text>
        </Col>
        {/* <Col p={0} cw="auto">
          <Text color={colorComputed}>{item?.procent}</Text>
        </Col> */}
      </Row>
      <Row v="bottom">
        <Col mr={2} cw="auto" p={0}>
          <Title level={4}>{item?.coupleValue}</Title>
        </Col>
        <Col p={0}>
          <Text type={'secondary'}>{item?.secondName}</Text>
        </Col>
      </Row>
    </Card>
  )
}
CryptoCouple.propTypes = {
  item: PropTypes.object
}

export default CryptoCouple
