import { Col, Img, Row, Text } from '@qonsoll/react-design'

import { useCurrentScreen } from 'helpers'

const Security = () => {
  const { isSmallScreen } = useCurrentScreen()
  const securityData = [
    `Advanced and highly reliable AWS-type microservice cloud architecture with multi-layer operational and physical protection.`,
    'Almost complete anonymity: Bitcoin and Ethereum exchanges are conducted without storing personal client information.',
    'Impeccable reputation: no instances of security breaches throughout the history of the crypto exchange.',
    '100% guarantee of the safety of all cryptocurrency transactions conducted through the exchange.',
    'Data backup for complete restoration in case of damage or failure.',
    'Firewall for monitoring and blocking suspicious network traffic.',
    'Risk assessment system to detect suspicious activity.',
    'Use of modern proactive attack blocking methods.',
    'Regular internal and external security audits.'
  ]

  return (
    <Row className="row-gap-32" noGutters style={{ margin: '0 -24px' }}>
      {securityData.map((item) => (
        <Col cw={isSmallScreen ? 12 : 2} className="px-24">
          <Img
            className="mb-12"
            width={20}
            src={'https://a.storyblok.com/f/120064/x/313ea07064/icon-3.svg'}
          />
          <Text style={{ color: '#333' }} variant="body2">
            {item}
          </Text>
        </Col>
      ))}
    </Row>
  )
}

export default Security
