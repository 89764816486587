const firebaseConfig = {
  apiKey: 'AIzaSyAOANaOjujzXlL-29ksmAZeMNxz23KGhUQ',
  authDomain: 'opt-change.firebaseapp.com',
  projectId: 'opt-change',
  storageBucket: 'opt-change.appspot.com',
  messagingSenderId: '614421073334',
  appId: '1:614421073334:web:76314d215e56f2d9ef0517'
}

export default firebaseConfig
