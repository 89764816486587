import { Steps } from 'antd'
import styled from 'styled-components'

const ExchangeStyled = styled.div`
  width: 100%;
  position: relative;
  min-height: 100vh;
  height: 100%;
  background-color: rgb(65, 70, 202);
  padding: ${({ padding }) => padding || '64px 32px'};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StepsStyled = styled(Steps)`
  max-width: 800px;
  width: 100%;

  .ant-steps-item {
    & .ant-steps-item-icon {
      background-color: transparent;
      border: 1px dashed var(--ql-color-white);

      & .ant-steps-icon {
        color: var(--ql-color-white);
      }
    }

    & .ant-steps-item-title {
      color: var(--ql-color-white) !important;
    }

    & .ant-steps-item-title::after {
      background-color: var(--ql-color-white) !important;
    }
  }

  .ant-steps-item-active {
    & .ant-steps-item-icon {
      background-color: var(--ql-color-accent);
      border-color: var(--ql-color-accent);
    }

    & .ant-steps-item-title {
      color: var(--ql-color-accent) !important;
    }
  }

  .ant-steps-item-finish {
    & .ant-steps-item-icon {
      background-color: var(--ql-color-accent);
      border-color: var(--ql-color-accent);
    }

    & .ant-steps-item-title {
      color: var(--ql-color-accent) !important;
    }

    & .ant-steps-item-title::after {
      background-color: var(--ql-color-accent) !important;
    }
  }
`

export { ExchangeStyled, StepsStyled }
