import { Col, Img, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'

const PopularCurrencyTableTitle = (props) => {
  const { currency } = props

  return (
    <Row>
      <Col>
        <div className="flex align-center">
          {currency?.icon ? (
            <Img mr={3} height="32px" width="32px" src={currency?.icon} />
          ) : null}

          <Text mr={2} fontWeight={600} color="#333" fontSize={16}>
            {currency.title}
          </Text>
          <Text color="#7b818c">{currency.subTitle}</Text>
        </div>
      </Col>
    </Row>
  )
}

PopularCurrencyTableTitle.propTypes = {
  currency: PropTypes.object.isRequired
}
export default PopularCurrencyTableTitle
