import { Button, Image, Typography } from 'antd'
import { useCallback, useMemo } from 'react'

import { Space } from 'antd'
import error from 'assets/error.svg'
import { useCurrentScreen } from 'helpers'
import { useHistory } from 'react-router-dom'

const PaymentError = () => {
  const history = useHistory()
  const { xs } = useCurrentScreen()

  const blockStyle = useMemo(
    () => ({ display: 'flex', justifyContent: 'center' }),
    []
  )
  const wrapperStyle = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '16px'
    }),
    []
  )

  const sizes = useMemo(
    () => ({ title: xs ? 4 : 3, image: xs ? '70%' : '50%' }),
    [xs]
  )

  const handleClick = useCallback(() => history.push('/dashboard'))

  return (
    <div style={wrapperStyle}>
      <Space direction="vertical" size={24}>
        <div style={blockStyle}>
          <Image
            preview={false}
            src={error}
            draggable={false}
            width={sizes?.image}
          />
        </div>
        <div style={blockStyle}>
          <Typography.Title style={{ textAlign: 'center' }} level={sizes.title}>
            Unscheduled maintenance
          </Typography.Title>
        </div>
        <div>
          <Typography.Paragraph style={{ textAlign: 'center', margin: '0px' }}>
            Sorry, maintenance is being carried out.
            <br />
            Buying cryptocurrency with USD will be available soon. Currently,
            you can sell cryptocurrency.
          </Typography.Paragraph>
        </div>
        <div style={blockStyle}>
          <Button
            block={false}
            onClick={handleClick}
            style={{ width: '200px' }}>
            To main
          </Button>
        </div>
      </Space>
    </div>
  )
}

export default PaymentError
