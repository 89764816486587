import { Button, Input, Space } from 'antd'
import { useCallback, useRef, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'

const useGetTableSearchProps = ({ placeholder } = {}) => {
  const searchInput = useRef(null)

  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm({ confirm: true })
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = useCallback(
    (dataIndex, searchFields) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close
      }) => (
        <div
          style={{
            padding: 8
          }}
          onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search by ${dataIndex}` || 'Search...'}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block'
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90
              }}>
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90
              }}>
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close()
              }}>
              Close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? 'var(--ql-color-primary)' : undefined
          }}
        />
      ),
      onFilter: (value, record) => {
        return searchFields?.some((field) =>
          record[field]
            ? record[field]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
            : ''
        )
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100)
        }
      },
      render: (text) => text
    }),
    []
  )

  return getColumnSearchProps
}

export default useGetTableSearchProps
