import styled from 'styled-components'

const ImageStyled = styled.img`
  width: 14%;
  height: auto;
  transform: scale(1);
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  }
`

const styles = {
  imageWrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

export { styles, ImageStyled }
