import { Col, Container, Row, Text, Title } from '@qonsoll/react-design'
import { CollapseStyled, PanelStyled } from './FrequentlyAskedQuestions.styled'

import PropTypes from 'prop-types'
import { useCurrentScreen } from 'helpers'

const FrequentlyAskedQuestions = (props) => {
  const { faq, withTitle = true } = props
  const { xs } = useCurrentScreen()

  return (
    <Container>
      {withTitle ? (
        <Row className="p-0">
          <Col className="p-0" mb={4}>
            <Title>FAQ</Title>
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col className="p-0" cw={12}>
          <CollapseStyled className="arrow" accordion>
            {faq?.map(({ header, description }) => (
              <PanelStyled header={<Title level={xs ? 5 : 4}>{header}</Title>}>
                <Text>{description}</Text>
              </PanelStyled>
            ))}
          </CollapseStyled>
        </Col>
      </Row>
    </Container>
  )
}

FrequentlyAskedQuestions.propTypes = {
  faq: PropTypes.array,
  withTitle: PropTypes.bool
}

export default FrequentlyAskedQuestions
