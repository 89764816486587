import { Button } from '@qonsoll/react-design'
import { ButtonSecondary } from 'components'

const useGetExchangeBaseActions = ({
  onCancel,
  onSubmit,
  submitText,
  cancelText
}) => {
  return {
    cancelButton: (
      <ButtonSecondary onClick={onCancel}>
        {cancelText || 'Cancel'}
      </ButtonSecondary>
    ),
    submitButton: (
      <Button onClick={onSubmit} htmlType="submit">
        {submitText || 'Continue'}
      </Button>
    )
  }
}

export default useGetExchangeBaseActions
