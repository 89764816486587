import { EXCHANGE_TYPES, PERCENT_LIST } from '__constants__'
import { useEffect, useState } from 'react'

import { Form } from 'antd'

let typingTimer

const useComputeCryptoPrice = (form) => {
  const [loading, setLoading] = useState(false)
  const dispatchType = Form.useWatch([EXCHANGE_TYPES.DISPATCH, 'type'], form)
  const receivingType = Form.useWatch([EXCHANGE_TYPES.RECEIVING, 'type'], form)
  const dispatchSum = Form.useWatch([EXCHANGE_TYPES.DISPATCH, 'sum'], form)
  const receivingSum = Form.useWatch([EXCHANGE_TYPES.RECEIVING, 'sum'], form)
  const stable = Form.useWatch(['stable'], form)

  const createFetchUrl = (symbol, symbolCouple) => {
    if (symbol === 'USD') symbol = 'USDT'
    if (symbolCouple === 'USD') symbolCouple = 'USDT'

    return `https://api.binance.com/api/v3/ticker/price?symbol=${symbol}${symbolCouple}`
  }

  const fetchApi = async (symbol, symbolCouple) => {
    let isReverse = false

    if (!symbol || !symbolCouple) return { data: null, isReverse: null }

    let priceJSON = await fetch(createFetchUrl(symbol, symbolCouple)).catch(
      () => {
        return null
      }
    )

    if (!priceJSON) {
      priceJSON = await fetch(createFetchUrl(symbolCouple, symbol)).catch(
        () => {
          return null
        }
      )
      priceJSON && (isReverse = true)
    }

    if (!priceJSON) return 0

    const data = (await priceJSON?.json()) ?? null
    return { data, isReverse }
  }

  const transformPrice = (price, sum, isReverse) => {
    if (!price) return 0

    let percent = PERCENT_LIST[`${dispatchType}${receivingType}`] ?? 0

    const computedPercent = (+price / 100) * percent
    const computedPrice = +price + computedPercent

    const computedSum = isReverse ? +sum / computedPrice : +sum * computedPrice

    // stable percent
    const endingSum = Number(computedSum)
    return endingSum <= 0 ? 0 : Number(endingSum.toFixed(8))
  }

  const usdtCase = (sum, type) => {
    const incrementValue =
      dispatchType === 'USDT' ? 0 : stable === 'BEP20' ? +0.015 : +0.01
    sum = incrementValue * sum + +sum
    sum = Number(sum.toFixed(3))

    form.setFieldValue([type, 'sum'], sum)
    setLoading(false)
  }

  // symbol - USDT
  // sum - 24
  // type - dispatch
  // symbolCouple - RUB

  const fetchData = async (symbol, sum, type, symbolCouple) => {
    if (
      (symbol === 'USD' || symbol === 'USDT') &&
      (symbolCouple === 'USD' || symbolCouple === 'USDT')
    ) {
      usdtCase(sum, type)
      return
    }

    const { data, isReverse } = await fetchApi(symbol, symbolCouple)

    const computedPrice = transformPrice(data?.price, sum, isReverse)

    form.setFieldValue([type, 'sum'], computedPrice)
    setLoading(false)
    return computedPrice
  }

  const computeCryptoPrice = async (...props) => {
    clearTimeout(typingTimer)
    !loading && setLoading(true)
    typingTimer = setTimeout(() => fetchData(...props), 500)
    return typingTimer
  }

  useEffect(() => {
    let isMounted = true
    isMounted &&
      fetchData(
        dispatchType,
        dispatchSum,
        EXCHANGE_TYPES.RECEIVING,
        receivingType
      )
    return () => (isMounted = false)
  }, [dispatchType])

  useEffect(() => {
    let isMounted = true
    isMounted &&
      fetchData(
        receivingType,
        receivingSum,
        EXCHANGE_TYPES.DISPATCH,
        dispatchType
      )
    return () => (isMounted = false)
  }, [receivingType])

  return [computeCryptoPrice, createFetchUrl, loading]
}

export default useComputeCryptoPrice
