import React from 'react'

const BinancecoinIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 20"
    version="1.1"
    style={{ transform: 'translate(10%, 0)' }}>
    <g id="surface1">
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#32C99C',
          fillOpacity: '1'
        }}
        d="M 8.875 0.988281 C 7.941406 1.917969 7.882812 1.988281 7.882812 2.121094 C 7.882812 2.199219 7.917969 2.308594 7.960938 2.371094 C 8.003906 2.429688 8.433594 2.871094 8.914062 3.347656 C 9.800781 4.226562 9.886719 4.292969 10.109375 4.238281 C 10.210938 4.214844 11.859375 2.621094 12.039062 2.371094 C 12.082031 2.308594 12.117188 2.199219 12.117188 2.121094 C 12.117188 1.988281 12.058594 1.917969 11.125 0.988281 C 10.214844 0.0820312 10.121094 0 10 0 C 9.878906 0 9.785156 0.0820312 8.875 0.988281 Z M 8.875 0.988281 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#32C99C',
          fillOpacity: '1'
        }}
        d="M 2.960938 6.90625 C 0.171875 9.691406 0 9.871094 0 10 C 0 10.128906 0.171875 10.308594 2.960938 13.09375 C 5.878906 16.015625 5.921875 16.054688 6.074219 16.054688 C 6.222656 16.054688 6.257812 16.023438 7.175781 15.109375 C 7.695312 14.589844 8.140625 14.117188 8.167969 14.054688 C 8.191406 13.996094 8.199219 13.898438 8.183594 13.839844 C 8.167969 13.765625 7.558594 13.128906 6.34375 11.914062 C 4.941406 10.503906 4.53125 10.074219 4.53125 10 C 4.53125 9.925781 4.941406 9.496094 6.34375 8.085938 C 7.558594 6.871094 8.167969 6.234375 8.183594 6.160156 C 8.199219 6.101562 8.191406 6.003906 8.167969 5.945312 C 8.140625 5.882812 7.695312 5.410156 7.175781 4.890625 C 6.257812 3.976562 6.222656 3.945312 6.074219 3.945312 C 5.921875 3.945312 5.878906 3.984375 2.960938 6.90625 Z M 2.960938 6.90625 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#32C99C',
          fillOpacity: '1'
        }}
        d="M 12.804688 4.910156 C 11.742188 5.96875 11.714844 6.011719 11.871094 6.257812 C 11.914062 6.328125 12.742188 7.175781 13.710938 8.144531 C 15.09375 9.527344 15.46875 9.921875 15.46875 10 C 15.46875 10.078125 15.09375 10.472656 13.710938 11.855469 C 12.742188 12.824219 11.914062 13.671875 11.871094 13.742188 C 11.714844 13.988281 11.742188 14.03125 12.804688 15.089844 C 13.742188 16.023438 13.777344 16.054688 13.925781 16.054688 C 14.078125 16.054688 14.121094 16.015625 17.039062 13.09375 C 19.828125 10.308594 20 10.128906 20 10 C 20 9.871094 19.828125 9.691406 17.039062 6.90625 C 14.121094 3.984375 14.078125 3.945312 13.925781 3.945312 C 13.777344 3.945312 13.742188 3.976562 12.804688 4.910156 Z M 12.804688 4.910156 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#32C99C',
          fillOpacity: '1'
        }}
        d="M 9.863281 7.90625 C 9.707031 7.976562 7.945312 9.753906 7.894531 9.890625 C 7.867188 9.964844 7.867188 10.035156 7.894531 10.105469 C 7.949219 10.253906 9.714844 12.023438 9.875 12.09375 C 9.980469 12.140625 10.019531 12.140625 10.128906 12.09375 C 10.289062 12.023438 12.054688 10.253906 12.105469 10.105469 C 12.136719 10.023438 12.132812 9.964844 12.09375 9.871094 C 12.035156 9.738281 10.203125 7.890625 10.128906 7.890625 C 10.105469 7.890625 10.0625 7.882812 10.03125 7.871094 C 10.003906 7.863281 9.929688 7.878906 9.863281 7.90625 Z M 9.863281 7.90625 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: '#32C99C',
          fillOpacity: '1'
        }}
        d="M 9.785156 15.816406 C 9.722656 15.855469 9.269531 16.292969 8.785156 16.78125 C 7.914062 17.660156 7.851562 17.746094 7.882812 17.953125 C 7.890625 17.996094 8.339844 18.476562 8.878906 19.015625 C 9.785156 19.917969 9.878906 20 10 20 C 10.121094 20 10.214844 19.917969 11.117188 19.019531 C 11.660156 18.476562 12.109375 18 12.117188 17.953125 C 12.152344 17.742188 12.09375 17.667969 11.199219 16.765625 C 10.699219 16.261719 10.246094 15.828125 10.1875 15.796875 C 10.042969 15.722656 9.925781 15.726562 9.785156 15.816406 Z M 9.785156 15.816406 "
      />
    </g>
  </svg>
)

export default BinancecoinIcon
