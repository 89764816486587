import { Route, Switch } from 'react-router-dom'

import { App } from './App'

const Navigator = () => {
  return (
    <Switch>
      <Route component={App} />
    </Switch>
  )
}

export default Navigator
