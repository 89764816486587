import { Layout } from 'antd'
import styled from 'styled-components'

const LayoutStyled = styled(Layout)`
  height: 100% !important;
  min-height: 100vh !important;
  min-width: 100vw !important;
  background-color: transparent;

  .ant-layout-content {
    height: 100%;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
  }
`
export { LayoutStyled }
