import {
  Box,
  Button,
  Col,
  Container,
  Input,
  Row,
  Text
} from '@qonsoll/react-design'
import { CURRENCY, EXCHANGE_TYPES } from '__constants__'
import { Form, notification } from 'antd'
import { generatePath, useHistory } from 'react-router-dom'

import { CheckIcon } from 'assets'
import { CurrencySelect } from 'components'
import { FormStyled } from './Exchange.styled'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { addThousandSeparators } from 'helpers'
import { createDocument } from 'services/helpers'
import { useComputeCryptoPrice } from 'hooks'
import { useEffect } from 'react'
import { v4 } from 'uuid'

const RightSide = (props) => {
  const { currency } = props
  const history = useHistory()

  const handleChangeClick = (orderId) =>
    history.push(generatePath(PATHS.EXCHANGE, { orderId }))

  const [form] = Form.useForm()

  const [computeCryptoPrice, createFetchUrl, loading] =
    useComputeCryptoPrice(form)

  const getMinValue = async (dispatch) => {
    let { type, sum } = dispatch

    let isReversePrice = false
    const targetPrice = 5

    if (type === 'USD' || type === 'USDT') {
      return {
        minValue: targetPrice,
        res: sum,
        enough: sum >= targetPrice
      }
    }

    let dataJSON = await fetch(createFetchUrl(type, 'USDT')).catch(() => null)

    if (!dataJSON) {
      dataJSON = await fetch(createFetchUrl('USDT', type)).catch(() => null)
      isReversePrice = true
    }

    const data = await dataJSON?.json()?.catch(() => null)

    if (!data) return null

    const pricePerOne = data?.price

    let minValue = isReversePrice
      ? (targetPrice / (1 / pricePerOne)).toFixed(2)
      : targetPrice / pricePerOne

    return {
      minValue: addThousandSeparators(minValue),
      res: sum * data?.price,
      enough: +sum >= +minValue
    }
  }

  const onFinish = async (credentials) => {
    const data = await getMinValue(credentials?.dispatch)
    await createDocument('firstSubmit', {
      sent: {
        coin: credentials?.dispatch,
        stable: credentials?.stable || null
      },
      receive: credentials?.receiving
    })

    if (!data?.enough) {
      return notification.error({
        key: 'minValue',
        message: 'Unable to create the operation',
        description: `Error. The minimum amount to create an operation ${data?.minValue} ${credentials?.dispatch?.type}.`
      })
    }

    const orderId = v4().substring(0, 8)

    Object.keys(localStorage ?? {})?.forEach(
      (key) => key?.length <= 8 && localStorage.removeItem(key)
    )

    // localStorage.clear()
    localStorage.setItem(orderId, JSON.stringify(credentials))
    localStorage.setItem(
      'currencies',
      JSON.stringify({
        receiving: credentials.receiving.type,
        dispatch: credentials.dispatch.type
      })
    )
    handleChangeClick(orderId)
  }

  useEffect(() => {
    const currenciesJSON = localStorage.getItem('currencies')
    const currencies = JSON.parse(currenciesJSON)
    form.setFieldsValue({
      dispatch: { type: currencies?.dispatch || 'USDT', sum: '0' },
      receiving: { type: currencies?.receiving || 'USD', sum: '0' },
      stable: 'TRC20'
    })
  }, [form])

  const receivingValue = {
    bitcoin: 'BTC',
    tether: 'USDT',
    bnb: 'BNB',
    ethereum: 'ETH',
    litecoin: 'LTC',
    ripple: 'XRP',
    ton: 'TON',
    not: 'NOT'
  }

  useEffect(() => {
    currency &&
      form.setFieldsValue({
        dispatch: { type: 'RUB', sum: '0' },
        receiving: { type: receivingValue[currency], sum: '0' }
      })
  }, [])

  const dispatchType = Form.useWatch([EXCHANGE_TYPES.DISPATCH, 'type'], form)
  const receivingType = Form.useWatch([EXCHANGE_TYPES.RECEIVING, 'type'], form)

  const handleChangeDispatchSum = (e) => {
    const value = e.target.value
    computeCryptoPrice(
      dispatchType,
      value,
      EXCHANGE_TYPES.RECEIVING,
      receivingType
    )
  }

  const handleChangeReceivingSum = (e) => {
    const value = e.target.value
    computeCryptoPrice(
      receivingType,
      value,
      EXCHANGE_TYPES.DISPATCH,
      dispatchType
    )
  }

  return (
    <FormStyled form={form} onFinish={onFinish}>
      <Container background="var(--ql-color-primary-darken1)">
        <Row noGutters>
          <Col cw={12}>
            <Row mb={3} noOuterGutters>
              {/* Entries */}
              <Col mb={3} cw={6}>
                <Text mb={1}>Send:</Text>
                <Box mb={3}>
                  <Form.Item name={[EXCHANGE_TYPES.DISPATCH, 'type']}>
                    <CurrencySelect
                      form={form}
                      item={CURRENCY}
                      changeType={receivingType}
                    />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={[EXCHANGE_TYPES.DISPATCH, 'sum']}>
                    <Input type="number" onChange={handleChangeDispatchSum} />
                  </Form.Item>
                </Box>
              </Col>

              {/* Exit */}
              <Col mb={2} cw={6}>
                <Box mb={1}>Receive:</Box>
                <Box mb={3}>
                  <Form.Item name={[EXCHANGE_TYPES.RECEIVING, 'type']}>
                    <CurrencySelect
                      item={CURRENCY}
                      changeType={dispatchType}
                      form={form}
                    />
                  </Form.Item>
                </Box>
                <Box>
                  <Form.Item name={[EXCHANGE_TYPES.RECEIVING, 'sum']}>
                    <Input type="number" onChange={handleChangeReceivingSum} />
                  </Form.Item>
                </Box>
              </Col>
            </Row>

            {/* Commission */}
            <Row mb={3} noGutters>
              <Col>
                <Box display="flex">
                  <Box mr={1} display="flex" alignItems="center">
                    <CheckIcon />
                  </Box>
                  <Text variant="body2" color="inherit">
                    All fees are included.
                  </Text>
                </Box>
              </Col>
            </Row>

            {/* Change it */}
            <Row noGutters>
              <Col>
                <Button htmlType="submit" loading={loading}>
                  Exchange
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </FormStyled>
  )
}

RightSide.propTypes = {
  currency: PropTypes.string
}

export default RightSide
